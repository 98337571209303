import {Component, Inject, Input, OnInit} from '@angular/core';
import {FeedbackService} from "../../services/feedback-service/feedback.service.interface";
import {RestfulFeedbackService} from "../../services/feedback-service/restful-feedback.service";
import {TranslateService} from "@ngx-translate/core";
import {SurveyFeedbackModel, UserPlanModel, ValidatorsUtil} from "shared-library";
import {FeedbackStatusEnum} from "../../enums/feedback-status.enum";

declare function uuidv4();

@Component({
  selector: 'app-feedback-panel',
  templateUrl: './feedback-panel.component.html',
  styleUrls: ['./feedback-panel.component.scss']
})
export class FeedbackPanelComponent implements OnInit {

  feedbackModel: SurveyFeedbackModel = new SurveyFeedbackModel()

  @Input() userPlan: UserPlanModel
  @Input() pageName: string

  feedbackStatusEnum = FeedbackStatusEnum
  showSuccess = false

  showEmailError = false
  currentLang
  emailValidator: RegExp = new RegExp(ValidatorsUtil.emailValidatorPattern)


  constructor(public translateService: TranslateService,
              @Inject('FeedbackService') public feedbackService: RestfulFeedbackService) {
    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })

  }

  ngOnInit(): void {}

  resetFeedback(){
    this.feedbackModel = new SurveyFeedbackModel()
    this.showEmailError = false
  }

  setupFeedbackType(type){
    if (type == this.feedbackModel.feedbackStatusId) this.feedbackModel = new SurveyFeedbackModel()
    else if (this.feedbackModel.feedbackStatusId == null) this.feedbackModel = new SurveyFeedbackModel(uuidv4(), 'CDA12D0F-293F-423D-888B-D5F8181AFF03', null, null, type, '', false, this.userPlan != null ? this.userPlan.id : null, false, this.pageName, false, null)
    else this.feedbackModel.feedbackStatusId = type
  }

  submitFeedback(){
    if (this.feedbackModel.IsParticipatingInResearch && (this.feedbackModel.email == null || this.feedbackModel.email.trim().length == 0 || !this.feedbackModel.email.trim().match(this.emailValidator))){
      this.showEmailError = true
      return
    }

    this.feedbackService.saveFeedback(this.feedbackModel).subscribe(() => {
      this.feedbackModel = new SurveyFeedbackModel()
      this.showSuccess = true
    })
  }
}
