import {Component, Inject, OnInit} from '@angular/core';
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import {ActivatedRoute} from "@angular/router";
import {InputTypeModel, mappers, QuestionModel, SectionModel, UserPlanSectionModel} from "shared-library";
import {TranslateService} from "@ngx-translate/core";
import {QuestionAnswerService} from "../../services/question-answer-service/question-answer.interface";
import mapToUserPlanSectionModels = mappers.mapToUserPlanSectionModels;
import {zip} from "rxjs";
import {GoogleAnalyticsServiceInterface} from "../../services/google-analytics-service/google-analytics.interface";
import {CookieService} from "ngx-cookie";

declare function openNewTab(url);

@Component({
  selector: 'app-read-only-view',
  templateUrl: './read-only-view.component.html',
  styleUrls: ['../../components/steps-banner/steps-banner.component.scss', '../step-three/step-three.component.scss', './read-only-view.component.scss']
})
export class ReadOnlyViewComponent implements OnInit {

  userPlan
  userPlanSections: UserPlanSectionModel[] = []

  currentLang: string

  constructor(@Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('ContentService') public contentService: ContentService,
              @Inject('QuestionAnswerService') public questionAnswerService: QuestionAnswerService,
              @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
              public route: ActivatedRoute,
              public translateService: TranslateService,
              public cookieService: CookieService) {
    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    let shareableId = this.route.snapshot.params["id"];
    this.cookieService.put('sharedId', shareableId)

    zip(this.userPlanService.getUserPlanById(shareableId), this.contentService.getContentForPage('Step3', this.currentLang == 'fr', shareableId)).subscribe(result => {
      this.userPlan = result[0]

      this.googleAnalyticsService.eventEmitter("online-version-visited", "visited", "Online Version Visited", "visited", this.userPlan.id)

      let importantNotes = result[1].filter(x => x.contentCode == 'ImportantNotes')[0]

      this.questionAnswerService.getSectionsAndQuestionsByStepAndPlanId(2, shareableId, true).subscribe(data => {
        this.userPlanSections = mapToUserPlanSectionModels(data)

        let importantNotesUserPlanSection = new UserPlanSectionModel()
        importantNotesUserPlanSection.section = new SectionModel('Important Notes', '', '', importantNotes.title, importantNotes.title, 0, [
          new QuestionModel('important-notes', [], new InputTypeModel('importantNotes', 'Paragraph Body'), false, [], [], null, null, 0, importantNotes.contentHTML, importantNotes.contentHTML)
        ], true)

        this.userPlanSections.unshift(importantNotesUserPlanSection)
      })
    })
  }

  downloadUserPlanPdf(){

    openNewTab(('https://' + window.location.host + (this.currentLang == 'fr' ? '#/fr/pdf/pdf-view' : '#/pdf/pdf-view')))
  }
}
