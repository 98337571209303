import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {QuestionAnswerService} from "./question-answer.interface";
import {AnswerModel, UserPlanSectionModel} from "shared-library";

@Injectable({
  providedIn: 'root'
})
export class RestfulQuestionAnswerService implements QuestionAnswerService {

  constructor(private httpClient: HttpClient) {
  }

  getSectionsAndQuestionsByStepAndPlanId(stepNumber: number, planId: string, finalView: boolean): Observable<UserPlanSectionModel[]> {
    return this.httpClient.get<UserPlanSectionModel[]>(environment.apiBaseUrl + `questionAnswer/${stepNumber}/${planId}/${finalView}`)
  }

  saveQuestionAnswers(planId: string, questions: AnswerModel[], sectionId: string): Observable<any> {
    questions = questions.filter(x => x.questionId != '' && x.questionId != null && x.questionId.length > 0)
    return this.httpClient.post<any>(environment.apiBaseUrl + `questionAnswer/${planId}/${sectionId}`, questions)
  }

  updateSectionStatus(planId: string, sectionId: string, statusId: string): Observable<any> {
    return this.httpClient.post<any>(environment.apiBaseUrl + `questionAnswer/updateStatus/${planId}/${sectionId}/${statusId}`, {})
  }
}
