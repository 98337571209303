import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {Router} from "@angular/router";

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  @Input() isDarkTheme: boolean = false

  userEmail: string = "";
  menuOpen: boolean = false;
  currentLang: string

  constructor(public translateService: TranslateService,
              public router: Router,
              public cdr: ChangeDetectorRef,
              @Inject('AuthService') private authService: AuthService) {

    //this.authService.getLoggedInEmail.subscribe(name => this.setUserEmail(name));
    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {

    this.userEmail = this.authService.getUserEmail()
    this.authService.updateLoggedInEmail.subscribe(email=> {

      this.setUserEmail(email)
    })

  }

  setUserEmail(email: string) {
    this.userEmail = email;
  }

  toggleMenu($event: Event) {
    $event.preventDefault()
    $event.stopPropagation()
    this.menuOpen = !this.menuOpen
    if (this.menuOpen){
      this.cdr.detectChanges()
      hideOnClickOutside(document.getElementById('support-menu'), this)
    }
  }

  logout() {
    this.menuOpen = false
    this.authService.gotoLogout()
  }

  viewMyAccount() {
    this.router.navigate([this.currentLang == 'en' ? 'my-account' : 'fr/mon-dossier'])
  }
}

function hideOnClickOutside(element, thisClass) {
  const outsideClickListener = event => {
    if (!element.contains(event.target)) {
      thisClass.menuOpen = false
      thisClass.cdr.detectChanges()
      removeClickListener()
    }
  }

  const removeClickListener = () => {
    document.removeEventListener('click', outsideClickListener)
  }

  document.addEventListener('click', outsideClickListener)
}
