import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "./user-info.sevice.interface";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {UserModel} from "shared-library";

@Injectable({
  providedIn: 'root'
})
export class RestfulUserInfoService implements UserInfoService{
  constructor(private httpClient: HttpClient) {
  }

  updateUserInformation(user: UserModel): Observable<any>{
    return this.httpClient.post<any>(environment.apiBaseUrl + `user`, user);
  }

  getUserInfo():Observable<any>{
    const url=`${environment.apiBaseUrl}user/getUserInfo`;
    return this.httpClient.get(url);
  }

  isAuthenticated():Observable<any>{
    const url=`${environment.apiBaseUrl}user/isAuthenticated`;
    return this.httpClient.get(url);
  }
  isNewUser():Observable<boolean>{
    const url=`${environment.apiBaseUrl}user/isNewUser`;
    return this.httpClient.get<boolean>(url);
  }
}
