<div class="banner-div noprint">
  <div class="ontario-row">

    <div class="step-div ontario-column ontario-small-12 ontario-medium-12 ontario-large-12 title-div">
      <h1>{{'Step2.WorkplaceSafetyPlan' | translate}}</h1>
    </div>
  </div>
</div>

<div class="page-body" *ngIf="userPlan">
  <div class="ontario-row">
    <div class="ontario-column ontario-small-12">
      <h2>{{userPlan.businessName}}</h2>
    </div>
  </div>

  <div class="ontario-row">
    <div class="ontario-column ontario-small-12 ontario-large-9">
      <app-plan-tombstone [includeDates]="true" [userPlan]="userPlan"></app-plan-tombstone>
    </div>
    <div class="information-panel ontario-column ontario-small-12 ontario-large-3 ontario-margin-bottom-40-!">

      <div class="grey-box-header ontario-column">
        <h1 id="how-to-use" [innerHTML]=""></h1>
      </div>
      <div class="grey-box-body ontario-column">
        <div class="img-link-div" [tabIndex]="0" (click)="downloadUserPlanPdf()" (keyup.enter)="downloadUserPlanPdf()">
          <img src="assets/images/checklist-blue.svg" aria-hidden="true">
          <div>
            <button class="link-like-span">{{'ReadOnly.DownloadWorkplaceSafetyPlan' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ontario-row">
    <div class="ontario-column ontario-small-12">
      <h3>{{'ReadOnly.COVIDSafetyPlan' | translate}}</h3>
    </div>

    <div class="ontario-column ontario-small-12 ontario-large-9 ontario-padding-bottom-40-!"
         *ngFor="let userPlanSection of userPlanSections; let i = index">

      <h4 class="border-bottom section-heading">
        {{currentLang == 'en' ? userPlanSection.section.sectionName : userPlanSection.section.sectionNameFr}}
      </h4>

      <ng-container *ngFor="let question of userPlanSection.section.questions">
        <div class="ontario-column ontario-small-12"
             *ngIf="question.hasAnyAnswer() || question.inputType.inputTypeName == 'Paragraph Heading' || question.inputType.inputTypeName == 'Paragraph Body' || question.inputType.inputTypeName == 'Bullet List'">
          <h5>
            <img src="assets/images/double-chevron.svg" aria-hidden="true">
            <p *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With Text') > -1"
               [innerHTML]="(question.inputType.inputTypeName.toLowerCase().indexOf('input') > -1 ? (currentLang == 'en' ? question.displayText : question.displayTextFr) + ' ' + question.answers[0].answerText : question.answers[0].answerText)"></p>
            <p *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With Numeric') > -1"
               [innerHTML]="(question.inputType.inputTypeName.toLowerCase().indexOf('input') > -1 ? (currentLang == 'en' ? question.displayText : question.displayTextFr) + ' ' + question.answers[0].answerNumeric : question.answers[0].answerNumeric)"></p>
            <p
              *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With Text') == -1 && question.inputType.inputTypeName.indexOf('Checkbox With Numeric') == -1"
              [innerHTML]="currentLang == 'en' ? question.displayText : question.displayTextFr"></p>
          </h5>

          <ng-container *ngIf="question.inputType.inputTypeName == 'Paragraph Heading'">
            <p class="paragraph-item" *ngFor="let paragraph of question.options"
               [innerHTML]="currentLang == 'en' ? paragraph.displayText : paragraph.displayTextFr"></p>
          </ng-container>

          <ul
            *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With List') > -1 || question.inputType.inputTypeName.indexOf('Bullet List') > -1">
            <li *ngFor="let item of question.options"
                [innerHTML]="currentLang == 'en' ? item.displayText : item.displayTextFr"></li>
          </ul>

          <ng-container
            *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With List') == -1 && (question.answers != null) && question.options != null && question.options.length > 0">
            <ng-container *ngFor="let answers of [question.sortAnswers()]">
              <ng-container *ngFor="let answer of answers; let i = index">
                <div class="ontario-row option-row" *ngIf="i%2 == 0">
                  <ng-container
                    *ngFor="let option of (i+1 <= question.answers.length-1 ? [question.getOptionById(answers[i].optionId), question.getOptionById(answers[i+1].optionId)] : [question.getOptionById(answers[i].optionId)])">
                    <div class="ontario-column ontario-small-12 ontario-large-6 selected-option-div">
                      <ng-container *ngFor="let inputType of [option.inputType.inputTypeName.toLowerCase()]">
                        <div class="checkmarked-row">
                        <img src="assets/images/checkmark-black.svg"
                             [attr.aria-label]="'Step3.SelectedOptionImage' | translate">
                        <p class="option-text"
                             *ngIf="inputType.indexOf('checkbox with text') > -1"
                             [innerHTML]="(inputType.indexOf('input') > -1 ? (currentLang == 'en' ? option.displayText : option.displayTextFr) + ' ' + question.getAnswerByOptionId(option.id).answerText : question.getAnswerByOptionId(option.id).answerText)"></p>
                        <p class="option-text"
                             *ngIf="inputType.indexOf('checkbox with text') == -1"
                             [innerHTML]="currentLang == 'en' ? option.displayText : option.displayTextFr"></p>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </div>
</div>
