<div class="banner-div noprint">
  <div class="ontario-row">

    <div class="step-div ontario-column ontario-medium-4 ontario-large-3"
         [ngClass]="activeStep == 1 ? 'ontario-small-6 active-step' : 'ontario-small-3'"
         [class.ontario-border-highlight--blue]="activeStep == 1">

      <img *ngIf="activeStep <= 1"
           class="ontario-margin-top-16-! ontario-margin-right-12-! step-icon"
           aria-hidden="true"
           alt="{{(activeStep == 1 ? 'General.UnderstandThePotentialRisksInYourWorkplaceActiveIconAltText' : 'General.UnderstandThePotentialRisksInYourWorkplaceInactiveIconAltText') | translate}}"
           src="{{activeStep == 1 ? 'assets/images/magnifying-triangle-blue.svg' : 'assets/images/magnifying-triangle-black.svg'}}">

      <div *ngIf="activeStep > 1"
           (click)="navigateAway(1)" (keyup.enter)="navigateAway(1)"
           class="completed-icon-div ontario-margin-top-12-! ontario-margin-right-12-! clickable"
           [class.clickable]="activeStep > 1">
        <img class="ontario-margin-top-16-! ontario-margin-right-12-! step-icon completed-icon"
             aria-hidden="true"
             alt="{{'CompletedIconAltText' | translate}}"
             src="assets/images/checkmark-white.svg">
      </div>

      <div (click)="navigateAway(1)" (keyup.enter)="navigateAway(1)"
           [class.clickable]="activeStep > 1"
           class="step-text ontario-small-12 ontario-medium-9 ontario-margin-top-12-! ontario-margin-bottom-16-!">{{'General.UnderstandThePotentialRisksInYourWorkplace' | translate}}</div>
    </div>

    <div class="step-div ontario-column ontario-medium-4 ontario-large-3"
         [ngClass]="activeStep == 2 ? 'ontario-small-6 active-step' : 'ontario-small-3'"
         [class.ontario-padding-left-32-!]="activeStep == 3"
         [class.ontario-border-highlight--blue]="activeStep == 2">
      <img class="chevron-icon ontario-margin-top-24-!" src="assets/images/chevron-right-grey.svg"
           [class.move-left]="activeStep == 2"
           aria-hidden="true">

      <img *ngIf="activeStep <= 2"
           class="ontario-margin-top-16-! ontario-margin-right-12-! step-icon"
           aria-hidden="true"
           alt="{{(activeStep == 2 ? 'General.CustomizeThePlanWithApplicableDetailsActiveIconAltText' : 'General.CustomizeThePlanWithApplicableDetailsInactiveIconAltText') | translate}}"
           src="{{activeStep == 2 ? 'assets/images/treasure-map-blue.svg' : 'assets/images/treasure-map-black.svg'}}">

      <div *ngIf="activeStep > 2"
           (click)="navigateAway(2)" (keyup.enter)="navigateAway(2)"
           class="completed-icon-div ontario-margin-top-12-! ontario-margin-right-12-! clickable">
        <img class="ontario-margin-top-16-! ontario-margin-right-12-! step-icon completed-icon"
             aria-hidden="true"
             alt="{{'CompletedIconAltText' | translate}}"
             src="assets/images/checkmark-white.svg">
      </div>
      <div (click)="navigateAway(2)" (keyup.enter)="navigateAway(2)"
           [class.clickable]="activeStep > 2"
           class="step-text ontario-small-12 ontario-medium-9 ontario-margin-top-12-! ontario-margin-bottom-16-!">{{'General.CustomizeThePlanWithApplicableDetails' | translate}}</div>
    </div>

    <div class="step-div ontario-column ontario-medium-4 ontario-large-3"
         [ngClass]="activeStep == 3 ? 'ontario-small-6 active-step' : 'ontario-small-3'"
         [class.ontario-padding-left-32-!]="activeStep == 3"
         [class.ontario-border-highlight--blue]="activeStep == 3">
      <img class="chevron-icon ontario-margin-top-24-!" src="assets/images/chevron-right-grey.svg"
           aria-hidden="true">

      <img *ngIf="activeStep <= 3"
           class="ontario-margin-top-16-! ontario-margin-right-12-! step-icon"
           aria-hidden="true"
           alt="{{(activeStep == 3 ? 'General.ReviewAndDownloadTheRecommendedPlanActiveIconAltText' : 'General.ReviewAndDownloadTheRecommendedPlanInactiveIconAltText') | translate}}"
           src="{{activeStep == 3 ? 'assets/images/checklist-blue.svg' : 'assets/images/checklist-black.svg'}}">
      <div *ngIf="activeStep > 3"
           class="completed-icon-div ontario-margin-top-12-! ontario-margin-right-12-!">
        <img class="ontario-margin-top-16-! ontario-margin-right-12-! step-icon completed-icon"
             aria-hidden="true"
             alt="{{'CompletedIconAltText' | translate}}"
             src="assets/images/checkmark-white.svg">
      </div>

      <div class="step-text ontario-small-12 ontario-medium-9 ontario-margin-top-12-! ontario-margin-bottom-16-!">{{'General.ReviewAndDownloadTheRecommendedPlan' | translate}}</div>
    </div>

    <div class="step-div ontario-column ontario-large-3 margin-top-space login-time">
      <div class="spacer-div"></div>
      <account *ngIf="isLoggedIn"></account>
      <login [stepNumber]="activeStep" (loginClicked)="loginClicked.emit()" *ngIf="!isLoggedIn"></login>
    </div>
  </div>
  <div class="ontario-row">
    <div class="step-div ontario-column ontario-large-12 margin-top-space row-login-time">
      <account *ngIf="isLoggedIn"></account>
      <login [stepNumber]="activeStep" (loginClicked)="loginClicked.emit()" *ngIf="!isLoggedIn"></login>
    </div>
  </div>
</div>
