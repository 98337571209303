import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription, timer} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  public _count: number = 0;
  private _timeoutSeconds: number = 60;

  public resetOnTrigger: boolean = true;

  public timerSubscription: Subscription;
  private timer: Observable<number>;
  public timeoutExpired: Subject<number> = new Subject<number>();

  constructor() {
    this.timeoutExpired.subscribe();
  }

  public startTimer() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();

    this.timer = this.getTimer();
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }

  public stopTimer() {
    this.timeoutExpired.unsubscribe();
    this.timerSubscription.unsubscribe();
  }

  public resetTimer() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe();

    this.timer = this.getTimer();
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }

  public timerComplete(n: number) {
    this.timeoutExpired.next(++this._count);

    if (this.resetOnTrigger) {
      this.startTimer();
    }
  }

  public getTimer(){
    return timer(this._timeoutSeconds * 1000);
  }
}
