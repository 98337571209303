import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../services/auth.service/auth.service.interface";
import {RestfulUserInfoService} from "../services/user-info-service/restful-user-info.service";
import {map} from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              public translateService: TranslateService,
              @Inject('AuthService') private authService: AuthService,
              @Inject('UserInfoService') public userInfoService: RestfulUserInfoService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userInfoService.isAuthenticated().pipe(
      map(data => {
        let authenticated = data.value.toLowerCase()=="true"
        if (authenticated) {
         return  true;
        }
        else {
          this.authService.login(state.url, this.translateService.currentLang)
        }
        return false;
      })
    )
  }





}
