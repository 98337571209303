<div class="banner-div" [class.dark-theme]="isDarkTheme">
  <div class="ontario-row">
    <div id="title" class="ontario-header__logo-container ontario-columns ontario-small-9">
      {{title}}
    </div>
    <div class="ontario-columns ontario-small-3 margin-top-space" [class.ontario-padding-bottom-16-!]="isLoggedIn">
      <div class="spacer-div"></div>
      <account id="account" *ngIf="isLoggedIn" class="account-drop-down-spacing" [isDarkTheme]="isDarkTheme"></account>
      <login id="login" *ngIf="!isLoggedIn"></login>
    </div>
  </div>
</div>
<div class="box-shadow"></div>

