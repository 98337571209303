import {
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {QuestionAnswerService} from "../../services/question-answer-service/question-answer.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ButtonsContainerComponent} from "../../core-components/buttons-container/buttons-container.component";
import {GoogleAnalyticsServiceInterface} from "../../services/google-analytics-service/google-analytics.interface";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import {
    ContentDisplayUtil,
    ContentModel, InputTypeModel,
    mappers,
    PlanTombstoneComponent, QuestionModel, SectionModel,
    UserPlanModel,
    UserPlanSectionModel
} from "shared-library";
import mapToUserPlanSectionModels = mappers.mapToUserPlanSectionModels;
import {zip} from "rxjs";
import {CookieService} from "ngx-cookie";
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {UserInfoService} from "../../services/user-info-service/user-info.sevice.interface";

declare function openNewTab(url);

@Component({
    selector: 'app-step-three',
    templateUrl: './step-three.component.html',
    styleUrls: ['../../core-components/buttons-container/buttons-container.component.scss', './step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

    userPlan: UserPlanModel = new UserPlanModel('')
    userPlanId: string

    currentLang: string
    userPlanSections: UserPlanSectionModel[] = []

    @ViewChild("tombstone") tombstone: PlanTombstoneComponent
    @ViewChild("buttons") buttons: ButtonsContainerComponent
    @ViewChild("iframe") iframe: ElementRef

    pageContent: ContentModel[]
    contentDisplayUtil = ContentDisplayUtil
    newlyRegistered: boolean = false
    isLoggedIn: boolean = false

    constructor(@Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
                @Inject('UserPlanService') public userPlanService: UserPlanService,
                @Inject('QuestionAnswerService') public questionAnswerService: QuestionAnswerService,
                @Inject('ContentService') public contentService: ContentService,
                @Inject('UserInfoService') private userInfoService: UserInfoService,
                public route: ActivatedRoute,
                public router: Router,
                public translateService: TranslateService,
                private vcRef: ViewContainerRef,
                private resolver: ComponentFactoryResolver,
                private cookieService: CookieService) {

        this.currentLang = translateService.currentLang
        translateService.onLangChange.subscribe(lang => {
            this.currentLang = lang.lang
        })
    }

    ngOnInit(): void {
        window.scrollTo(0, 0)

        this.userPlanId = this.cookieService.get("userPlanId");
        this.newlyRegistered = this.route.snapshot.params["newlyRegistered"];

        this.userInfoService.isAuthenticated().subscribe(data => {
            this.isLoggedIn = data.value.toLowerCase()=="true"
        })

        if (this.userPlanId) {
            zip(this.userPlanService.getUserPlanById(this.userPlanId), this.contentService.getContentForPage('Step3', this.currentLang == 'fr', this.userPlanId)).subscribe(result => {
                this.userPlan = result[0]
                this.pageContent = result[1]

                let importantNotes = result[1].filter(x => x.contentCode == 'ImportantNotes')[0]

                this.questionAnswerService.getSectionsAndQuestionsByStepAndPlanId(2, this.userPlanId, true).subscribe(data => {
                    this.userPlanSections = mapToUserPlanSectionModels(data)

                    let importantNotesUserPlanSection = new UserPlanSectionModel()
                    importantNotesUserPlanSection.section = new SectionModel('Important Notes', '', '', importantNotes.title, importantNotes.title, 0, [
                        new QuestionModel('important-notes', [], new InputTypeModel('importantNotes', 'Paragraph Body'), false, [], [], null, null, 0, importantNotes.contentHTML, importantNotes.contentHTML)
                    ], true)

                    this.userPlanSections.unshift(importantNotesUserPlanSection)
                })
            })
        } else this.router.navigate([this.currentLang == 'fr' ? '/fr' : '/'])

    }

    navigateToEdit(sectionName) {
        sectionName = ContentDisplayUtil.parseStringForUrl(sectionName)
        let backRoute = this.currentLang == 'en' ? '/step-2' : 'fr' + '/etape-2'
        backRoute += '/section/' + sectionName
        this.router.navigate([backRoute])
    }

    startNewPlan() {
        let path = this.currentLang == 'en' ? 'step-1' : 'etape-1'
        this.userPlanService.createUserPlan().subscribe(userPlanId => {
            this.cookieService.put("userPlanId", userPlanId)
            this.router.navigate([(this.currentLang == 'en' ? '' : 'fr/') + path])
        })
    }

    downloadUserPlanPdf() {
        openNewTab(('https://' + window.location.host + (this.currentLang == 'fr' ? '/#/fr/pdf/pdf-view' : '/#/pdf/pdf-view')))
    }

    monitorSharing() {
        this.googleAnalyticsService.eventEmitter("copy-link", "copied", "Online Version Link Copied", "click", this.userPlanId);
    }

    duplicateThisPlan() {
        this.userPlanService.cloneUserPlan(this.userPlanId).subscribe(newUserPlanId => {
            let path = this.currentLang == 'en' ? 'step-1/clone/true' : 'etape-1/clone/true'
            this.cookieService.put("userPlanId", newUserPlanId)
            this.router.navigate([(this.currentLang == 'en' ? '' : 'fr/') + path])
        })
    }
}
