import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PublicSecureService} from "../../services/public-secure/public-secure.service.interface";
import {AuthService} from "../../services/auth.service/auth.service.interface";

@Component({
  selector: 'app-public-secure-auth',
  templateUrl: './public-secure-auth.component.html',
  styleUrls: ['./public-secure-auth.component.scss']
})
export class PublicSecureAuthComponent implements OnInit {
  email: string = "";

  constructor(private route: ActivatedRoute,
              private router: Router,
              @Inject('PublicSecureService') private publicSecureService: PublicSecureService,
              @Inject('AuthService') private authService: AuthService
  ) {
  }

  ngOnInit(): void {
  }

}
