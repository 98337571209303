import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {PublicSecureService} from "./public-secure.service.interface";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class RestfulPublicSecureService  implements PublicSecureService{
  private apiBaseUrl;

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl ;
  }

  getAuthorizationUrl(lang='en'):Observable<any>{
    const url=`${this.apiBaseUrl}PublicSecure?language=${lang}`;
    return this.http.get(url)
  }

  acquireTokenByAuthorizationCode(code :string):Observable<any>{
    const url=`${this.apiBaseUrl}PublicSecure/token?code=${code}`;
    return this.http.get(url)
  }

  getLogoutUrl(lang:string):Observable<any>{
    const url=`${this.apiBaseUrl}PublicSecure/logout?language=${lang}`;
    return this.http.get(url)
  }

  getUserProfileUrl():Observable<any>{
    const url=`${this.apiBaseUrl}PublicSecure/userprofile`;
    return this.http.get(url)
  }

  getRefreshToken():Observable<any>{
    const url=`${this.apiBaseUrl}Auth/refreshToken`;
    return this.http.get(url, {observe: 'response'})
  }
}
