import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ButtonsContainerComponent} from "../../core-components/buttons-container/buttons-container.component";
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {QuestionAnswerService} from "../../services/question-answer-service/question-answer.interface";
import {zip} from "rxjs";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import {
  ContentDisplayUtil,
  ContentModel,
  InputTypeModel,
  mappers,
  QuestionModel,
  ResetConfirmationComponent,
  SectionModel,
  UserPlanModel,
  ValidationModel,
  ValidatorsUtil,
  AnswerModel
} from 'shared-library';
import mapToSectionModel = mappers.mapToSectionModel;
import {CookieService} from "ngx-cookie";

declare function uuidv4(): string;

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})

export class StepOneComponent implements OnInit {

  userPlanId: string = ""
  userPlan: UserPlanModel = new UserPlanModel("")

  businessRegions: any[] = []

  sections: SectionModel[] = []

  currentLang: string
  showResetConfirmation: boolean = false

  showErrorBanner: boolean = false
  errorList: string[] = []

  showInputs: boolean = true

  @ViewChild("buttons") public buttons: ButtonsContainerComponent | undefined;
  @ViewChild("resetConfirmation") public resetConfirmation: ResetConfirmationComponent | undefined;

  pageContent: ContentModel[]

  contentDisplayUtil = ContentDisplayUtil
  newlyRegistered: boolean = false
  cloned: boolean = false

  constructor(public translateService: TranslateService,
              public route: ActivatedRoute,
              public cdr: ChangeDetectorRef,
              @Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('QuestionAnswerService') public questionAnswerService: QuestionAnswerService,
              @Inject('ContentService') public contentService: ContentService,
              public router: Router,
              private cookieService: CookieService) {
    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    //push in the default workplace section (this will not come from the service, though the user plan model will and will be bound to this section)
    window.scrollTo(0, 0)

    this.userPlanId = this.cookieService.get("userPlanId");
    this.newlyRegistered = this.route.snapshot.params["newlyRegistered"];
    this.cloned = this.route.snapshot.params["clone"];
    if (this.userPlanId) {
      zip(this.userPlanService.getUserPlanById(this.userPlanId), this.questionAnswerService.getSectionsAndQuestionsByStepAndPlanId(1, this.userPlanId, false), this.userPlanService.getBusinessRegionsAndPublicHealthUnits()).subscribe(data => {
        this.userPlan = data[0]
        this.sections.push(...data[1].map(x => mapToSectionModel(x.section)));
        this.businessRegions = data[2]

        this.sections.sort((a, b) => {
          return a.sectionDisplayOrderNumber > b.sectionDisplayOrderNumber ? 1 : -1
        })
        this.initializeUserPlanForModelBindingReuse()
      })

      this.contentService.getContentForPage('Step1', this.currentLang == 'fr', this.userPlanId).subscribe(data => {
        this.pageContent = data
      })
    } else this.router.navigate([this.currentLang == 'fr' ? '/fr': '/'])
  }

  initializeUserPlanForModelBindingReuse() {
    if (this.sections != null && this.sections[0] != null) this.sections[0].questions = this.sections[0].questions.filter(x => x.origReferenceParam == null)
    if (this.sections != null && this.sections[1] != null) this.sections[1].questions = this.sections[1].questions.filter(x => x.origReferenceParam == null)

    Object.keys(this.userPlan).forEach(x => {
      this.createQuestionModelByOtherModelParamKey(x)
    })
    this.sections.forEach((x: SectionModel) => {
      x.sortQuestions()
    })
    this.userPlan.sections = this.sections
  }

  createQuestionModelByOtherModelParamKey(paramKey: string): any {
    // @ts-ignore
    let val: any = this.userPlan[paramKey]
    if (["businessName",
      "contactName",
      "contactEmailAddress",
      "contactPhoneNumber",
      "contactPostalCode",
      "safetyPlanName"].indexOf(paramKey) > -1) {
      // @ts-ignore
      let parsedAnswer = new AnswerModel('', '', "", "", val, '', '');
      let relevantInputType = new InputTypeModel('', 'Text Input');

      let section = this.sections.filter(x => x.id.toLowerCase() == (paramKey != 'businessName' ? 'f3db39df-03f1-46de-b22f-f09e9ec24ecc' : 'd912a1e6-7298-4638-ad56-09b769c44999'))[0]
      if (section != null) {
        let question = new QuestionModel(uuidv4(), [parsedAnswer], relevantInputType, paramKey != 'safetyPlanName', [], [], paramKey == 'contactPostalCode' ? 'e.g. A0A 0A0' : '', paramKey == 'contactPostalCode' ? 'p. ex. A0A 0A0' : '', paramKey == 'businessName' ? 0 : paramKey == 'contactPostalCode' ? 35 : 30, this.contentDisplayUtil.getQuestionTranslationByParamKey(paramKey, false), this.contentDisplayUtil.getQuestionTranslationByParamKey(paramKey, true), '', '', this.userPlan, paramKey)
        question.validation = this.determineValidation(paramKey)
        section.questions.push(question)
      }
    } else if ("healthUnitUserPlans" == paramKey) {
      //BusinessRegion/PHU dropdowns
      let section = this.sections.filter(x => x.id.toLowerCase() == 'f3db39df-03f1-46de-b22f-f09e9ec24ecc')[0]
      if (section != null) section.questions.push(new QuestionModel(uuidv4(), [], new InputTypeModel('', 'Health Units'), false, [], [], '', '', 37, 'Public Health Unit', 'Bureau de santé', '', '', this.userPlan, paramKey, undefined, undefined, undefined))
    }
  }

  determineValidation(paramKey: string) {
    switch (paramKey) {
      case "contactPostalCode":
        return new ValidationModel('Please provide a valid postal code. (eg. A0A 0A0)', 'Veuillez fournir un code postal valid (ex. A0A 0A0)', 'Your postal code is invalid', 'Votre code postal n\'est pas valide', ValidatorsUtil.postalCodeRegex, 7)
      case "contactPhoneNumber":
        return new ValidationModel('Please provide a valid phone number. (eg. 333-333-3333)', 'Veuillez fournir un numéro de téléphone valide (ex. 333-333-3333)', 'Your phone number is invalid', 'Votre numéro de téléphone n\'est pas valide', ValidatorsUtil.phonePattern, 30)
      case "contactEmailAddress":
        return new ValidationModel('Please provide a valid email address. (eg. yourlogin@domain.ca)', 'Veuillez fournir une adresse courriel valide (ex. nomutilisateur@domaine.ca)','Your email address is invalid', 'Votre adresse courriel n\'est pas valide', ValidatorsUtil.emailValidatorPattern)
    }
    return null;
  }

  showReset() {
    this.showResetConfirmation = true;
    this.cdr.detectChanges()

    // @ts-ignore
    document.getElementById("warning-icon-background").scrollIntoView();
    let bannerHeight = document.getElementsByClassName('banner-div')[0].scrollHeight + 45
    window.scrollBy(0, -1 * bannerHeight)
  }

  showError(errors: string[]) {
    this.errorList = errors
    this.showErrorBanner = true

    this.sections.forEach(section => section.questions.forEach(question => {
      question.showError = true
    }))
    this.cdr.detectChanges()

    // @ts-ignore
    document.getElementsByClassName("error-icon-background")[0].scrollIntoView();
    let bannerHeight = document.getElementsByClassName('banner-div')[0].scrollHeight + 45
    window.scrollBy(0, -1 * bannerHeight)
  }

  toggleInputs(visible: boolean) {
    if (visible) this.initializeUserPlanForModelBindingReuse()

    this.showInputs = visible;
    this.cdr.detectChanges()
  }

  loginClicked(){
    this.buttons.nextClicked(true)
  }
}
