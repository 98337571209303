<div class="buttons-column ontario-column ontario-small-12 ontario-medium-12 ontario-large-9">
  <app-success-banner *ngIf="showSuccess"
                      [successMessage]="'Feedback.ThankYouForSubmitting' | translate"
                      [successTitle]="'Feedback.FeedbackSubmitted' | translate">
  </app-success-banner>

  <div id="feedback-container" class="ontario-padding-top-24-! ontario-margin-bottom-40-!">

    <h3>{{"Feedback.Feedback" | translate}}</h3>

    <div class="grey-box-body">
      <div class="icon-panel ontario-row" [class.ontario-padding-bottom-16-!]="feedbackModel.feedbackStatusId == null">
        <div id="image-div" class="ontario-column ontario-small-12 ontario-large-3">
          <button class="face-icon"
                  (click)="setupFeedbackType(feedbackStatusEnum.POSITIVE)"
                  (keyup.enter)="setupFeedbackType(feedbackStatusEnum.POSITIVE)"
                  [ngClass]="feedbackModel.feedbackStatusId === feedbackStatusEnum.POSITIVE ? 'green-check' : 'green-face'"
                  [attr.aria-label]="(feedbackModel.feedbackStatusId === feedbackStatusEnum.POSITIVE ? 'Feedback.ClickedHappyAltText' : 'Feedback.HappyAltText') | translate">
          </button>
          <button class="face-icon"
                  (click)="setupFeedbackType(feedbackStatusEnum.NEUTRAL)"
                  (keyup.enter)="setupFeedbackType(feedbackStatusEnum.NEUTRAL)"
                  [ngClass]="feedbackModel.feedbackStatusId === feedbackStatusEnum.NEUTRAL ? 'orange-check' : 'orange-face'"
                  [attr.aria-label]="(feedbackModel.feedbackStatusId === feedbackStatusEnum.NEUTRAL ? 'Feedback.ClickedMehAltText' : 'Feedback.MehAltText') | translate">
          </button>
          <button class="face-icon"
                  (click)="setupFeedbackType(feedbackStatusEnum.NEGATIVE)"
                  (keyup.enter)="setupFeedbackType(feedbackStatusEnum.NEGATIVE)"
                  [ngClass]="feedbackModel.feedbackStatusId === feedbackStatusEnum.NEGATIVE ? 'red-check' : 'red-face'"
                  [attr.aria-label]="(feedbackModel.feedbackStatusId === feedbackStatusEnum.NEGATIVE ? 'Feedback.ClickedAngryAltText' : 'Feedback.AngryAltText') | translate">
          </button>
        </div>
        <div class="ontario-column ontario-small-12 ontario-large-9">
          {{"Feedback.WeWouldLikeToHear" | translate}}
        </div>

        <div class="dashed-border-bottom ontario-column" *ngIf="feedbackModel.feedbackStatusId != null"></div>
      </div>

      <div id="feedback-content" class="ontario-row ontario-padding-bottom-32-!"
           *ngIf="feedbackModel.feedbackStatusId != null">


        <div class="ontario-column ontario-small-12 ontario-large-6 participation-info ontario-padding-bottom-16-!">
          <h4>{{"Feedback.WeWantYourParticipation" | translate}}</h4>
          <p> {{"Feedback.ThankYouForUsingTheWorkplaceSafetyPlanBuilder" | translate}}</p>

          <h4>{{"Feedback.YourPrivacyMatters" | translate}}</h4>
          <p [innerHTML]='"Feedback.TheFeedbackYouProvide" | translate'></p>
          <p [innerHTML]='"Feedback.YourFeedbackWillBeMadeIntoAnonymousData" | translate'></p>
          <p [innerHTML]='"Feedback.IfYouDoProvideContact" | translate'></p>

          <h4>{{"Feedback.Accessibility" | translate}}</h4>
          <p [innerHTML]='"Feedback.WeAreCommittedToProvidingAccessibleCustomerService" | translate'></p>
        </div>

        <div class="ontario-column ontario-small-12 ontario-large-6">
          <div class="ontario-small-10 input-label">{{"Feedback.FeedbackFor" | translate}}</div>
          <div class="ontario-small-12 input-div ontario-margin-top-8-! not-table-cell ontario-padding-bottom-24-!">
            <div class="radio-button-option-esque"
                 [tabIndex]="0"
                 [class.selected-option]="feedbackModel.isPageRelated === true"
                 (click)="feedbackModel.isServiceRelated = false; feedbackModel.isPageRelated = true"
                 (keyup.enter)="feedbackModel.isServiceRelated = false; feedbackModel.isPageRelated = true">
              <div>{{'Feedback.AboutThePage' | translate}}</div>
            </div>
            <div class="radio-button-option-esque"
                 [tabIndex]="0"
                 [class.selected-option]="feedbackModel.isServiceRelated === true"
                 (click)="feedbackModel.isServiceRelated = true; feedbackModel.isPageRelated = false"
                 (keyup.enter)="feedbackModel.isServiceRelated = true; feedbackModel.isPageRelated = false">
              <div>{{'Feedback.General' | translate}}</div>
            </div>
          </div>

          <label for="feedback-message">{{"Feedback.FeedbackMessage" | translate}}</label>
          <textarea [placeholder]="'Feedback.FeedbackPlaceholder' | translate" id="feedback-message"
                    [(ngModel)]="feedbackModel.feedbackContent"></textarea>

          <div class="ontario-checkboxes__item ontario-margin-top-24-! ontario-padding-bottom-12-!">
            <input type="checkbox"
                   [(ngModel)]="feedbackModel.IsParticipatingInResearch"
                   id="research"
                   class="ontario-checkboxes__input">
            <label class="ontario-checkboxes__label parent-checkbox"
                   for="research">{{"Feedback.IAmInterestedInParticipating" | translate}}</label>
          </div>

          <div class="ontario-column email-input">
            <label>{{"Feedback.EmailAddress" | translate}}

              <span class="optional"
                    *ngIf="!feedbackModel.IsParticipatingInResearch">({{"General.Optional" | translate}})</span></label>
            <input type="text" id="email" [(ngModel)]="feedbackModel.email">
            <app-error-message id="required-err"
                               *ngIf="showEmailError && feedbackModel.IsParticipatingInResearch && (feedbackModel.email == null || feedbackModel.email.trim().length == 0)"
                               [errorMsg]="currentLang == 'fr' ? 'Veuillez fournir une adresse de courriel de votre entreprise' : 'Please provide a business email address'"></app-error-message>
            <app-error-message id="invalid-err"
                               *ngIf="showEmailError && feedbackModel.IsParticipatingInResearch && feedbackModel.email != null && this.feedbackModel.email.trim().length > 0 && !feedbackModel.email.trim().match(emailValidator)"
                               [errorMsg]="currentLang == 'fr' ? 'Veuillez fournir une adresse de courriel de votre entreprise valide' : 'Please provide a valid business email address'"></app-error-message>
          </div>

          <div class="ontario-column bottom-buttons">
            <button id="submit-button" class="ontario-button--primary ontario-button" (click)="submitFeedback()"
                    (keyup.enter)="submitFeedback()">{{"Feedback.SubmitFeedback" | translate}}</button>
            <button id="cancel-button" class="link-like-span" (click)="resetFeedback()"
                    (keyup.enter)="resetFeedback()">{{"Feedback.Cancel" | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
