<div class="ontario-small-12 ontario-medium-9 alert-box ontario-margin-bottom-48-!" *ngIf="!isLoggedIn && blueBoxContent">
  <h4>
    <div id="info-icon-background">
      <img id="info-icon" src="assets/images/info-icon.svg" [attr.aria-label]="'General.InfoIconAltText' | translate"
           aria-hidden="true">
    </div>
    {{blueBoxContent.title}}
  </h4>
  <div class="more-left-margin">
    <div class="info-text" [innerHTML]="blueBoxContent.contentHTML">
    </div>
  </div>
</div>

<div class="ontario-small-12 ontario-medium-12 ontario-large-9 error-box ontario-margin-bottom-48-!"
     *ngIf="stepNumber == 2 && invalidSections">
  <h4>
    <div id="error-icon-background">
      <img id="error-icon" src="assets/images/white-exclamation.svg" aria-hidden="true"
           [attr.aria-label]="'General.InfoIconAltText' | translate">
    </div>
    {{'General.IncompleteSectionsTitle' | translate}}
  </h4>
  <div class="info-text">
    {{'General.IncompleteSectionsContent' | translate}}
  </div>
</div>


<div id="buttons-container" class="ontario-padding-top-24-! ontario-margin-bottom-40-! noprint">


  <button *ngIf="backRoute != null && backRoute.length > 0" id="back-button" (click)="backClicked()"
          (keyup.enter)="backClicked()">{{'General.Back' | translate}}</button>
  <button *ngIf="stepNumber == 1" id="reset-button" class="link-like-span" (click)="resetClicked()"
          (keyup.enter)="resetClicked()">{{'General.Reset' | translate}}</button>

  <button id="next-button"
          *ngIf="nextRoute != null && nextRoute.length > 0 && nextButtonText != null && nextButtonText.length > 0"
          class="ontario-button--primary ontario-button" (click)="nextClicked()"
          (keyup.enter)="nextClicked()">{{isLoggedIn ? nextButtonText : 'General.ContinueWithoutSaving' | translate}}</button>

  <button id="login-register-btn"
          *ngIf="!isLoggedIn && ((nextRoute != null && nextRoute.length > 0 && nextButtonText != null && nextButtonText.length > 0) || stepNumber == 3)"
          class="ontario-button login-spacing login-button"
          (click)="nextClicked(true)" (keyup.enter)="nextClicked(true)">{{'General.CreateAccountToSave' | translate}}</button>
</div>
