<app-steps-banner [activeStep]="1" (loginClicked)="loginClicked()"></app-steps-banner>

<div class="ontario-row ontario-margin-top-40-!">

    <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9">
        <h2 class="step-title ontario-margin-bottom-8-!">{{contentDisplayUtil.getContentForPage(pageContent, 'TitleSubtext').title}}</h2>
        <div class="step-subtext ontario-margin-bottom-24-!"
             [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'TitleSubtext').contentHTML">}
        </div>
    </div>
    <app-grey-box [content]="contentDisplayUtil.getContentForPage(pageContent, 'SidebarContent')"></app-grey-box>

    <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9 ontario-padding-bottom-40-!">
        <app-success-banner *ngIf="newlyRegistered"
                            [successTitle]="('Profile.YourPlanHasBeenSavedToYourAccount' | translate)"
                            [buttonText]="('Profile.ViewMyAccount' | translate)"></app-success-banner>
        <app-success-banner *ngIf="cloned"
                            [successTitle]="('Profile.YourPlanHasBeenCloned' | translate)"></app-success-banner>

        <div *ngIf="showErrorBanner" class="ontario-margin-top-40!">
            <app-error-banner [errorList]="errorList" [sectionMandatory]="false"></app-error-banner>
        </div>

        <div *ngIf="showResetConfirmation" class="ontario-margin-top-40-!">
            <reset-confirmation #resetConfirmation id="reset-confirmation" [stepNumber]="1"
                                [userPlan]="userPlan" (toggleInputs)="toggleInputs($event)"
                                (closeReset)="showResetConfirmation = false"></reset-confirmation>
        </div>

        <ng-container *ngFor="let section of sections; let i = index">
            <h3 class="section-title border-bottom ontario-padding-bottom-4-! ontario-margin-bottom-24-!"
                *ngIf="section.sectionNameFr != null && section.sectionNameFr.length > 0">{{currentLang == 'en' ? section.sectionName : section.sectionNameFr}}</h3>

            <app-input-type-loop *ngIf="section.questions != null && showInputs" [questions]="section.questions"
                                 [finalSectionOnPage]="i == sections.length - 1"
                                 [userPlanId]="userPlanId"
                                 [regions]="businessRegions"></app-input-type-loop>
        </ng-container>
    </div>
    <div
            class="buttons-column ontario-column ontario-small-12 ontario-medium-12 ontario-large-9 ontario-padding-bottom-40-!">
        <app-buttons-container #buttons [backRoute]="'/'"
                               [stepNumber]="1"
                               [nextButtonText]="'Step1.ContinueToStep2' | translate"
                               [nextRoute]="currentLang == 'en' ? '/step-2' : '/etape-2'"
                               [userPlan]="userPlan"
                               [blueBoxContent]="contentDisplayUtil.getContentForPage(pageContent, 'BlueBox')"
                               (resetClickedEmitter)="showReset()"
                               (validationErrorEmitter)="showError($event)"></app-buttons-container>
    </div>
    <app-feedback-panel [userPlan]="userPlan" [pageName]="'Step 1'"></app-feedback-panel>
</div>
