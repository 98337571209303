<div *ngIf="userEmail !='' " class="account-container" [class.dark-theme]="isDarkTheme">

  <div class="email-div">
    <div id="email" [style.color]="isDarkTheme ? 'white' : '#1a1a1a'" >{{userEmail}}</div>
    
    <button (click)="toggleMenu($event)" (keyup.enter)="toggleMenu($event)">
      <img [src]="isDarkTheme ? 'assets/images/chevron-down-grey-back.svg' :'assets/images/chevron-down-black-grey-border.svg'"
           [attr.aria-label]="'Profile.AccountDropDownAlt' | translate">
    </button>

    <div id="support-menu" class="support-dropdown" tabindex="0" *ngIf="menuOpen">

      <div class="option" id="myAccount" tabindex="1"
           (keyup.enter)="viewMyAccount()" (click)="viewMyAccount()">
        <div>{{"General.MyAccount" | translate}}</div>
      </div>

      <div class="option" id="logout" tabindex="2" (keypress)="logout()" (click)="logout()">
        <div>{{"General.Logout" | translate}}</div>
      </div>
    </div>
  </div>
</div>
