import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LocalizationGuard} from "./translation/guard/localization.guard";
import {HomepageComponent} from "./page-components/homepage/homepage.component";
import {StepOneComponent} from "./page-components/step-one/step-one.component";
import {StepTwoComponent} from "./page-components/step-two/step-two.component";

import {AuthGuard} from "./guards/auth.guard";
import {StepThreeComponent} from "./page-components/step-three/step-three.component";
import {ProfileComponent} from "./page-components/my-account/profile.component";
import {PdfViewComponent} from "./page-components/pdf-view/pdf-view.component";
import {ReadOnlyViewComponent} from "./page-components/read-only-view/read-only-view.component";

const routes: Routes = [
    {path: 'fr/mon-dossier', component: ProfileComponent, canActivate: [AuthGuard,LocalizationGuard],data:{title:'Profile.Title'}},
    {path: 'my-account', component: ProfileComponent, canActivate: [AuthGuard,LocalizationGuard],data:{title:'Profile.Title'}},

    {path: 'fr', component: HomepageComponent, canActivate: [LocalizationGuard],data:{title:'Homepage.Title'}},

    {path: 'fr/etape-1', component: StepOneComponent, canActivate: [LocalizationGuard],data:{title:'Step1.Title'}},
    {path: 'fr/etape-1/clone/:clone', component: StepOneComponent, canActivate: [LocalizationGuard],data:{title:'Step1.Title'}},
    {path: 'fr/etape-1/:newlyRegistered', component: StepOneComponent, canActivate: [LocalizationGuard],data:{title:'Step1.Title'}},
    {path: 'fr/etape-2', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'fr/etape-2/:newlyRegistered', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'fr/etape-2/section/:sectionName', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'fr/etape-2/section/:sectionName/:newlyRegistered', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'fr/etape-3', component: StepThreeComponent, canActivate: [LocalizationGuard],data:{title:'Step3.Title'}},
    {path: 'fr/etape-3/:newlyRegistered', component: StepThreeComponent, canActivate: [LocalizationGuard],data:{title:'Step3.Title'}},

    {path: 'step-1', component: StepOneComponent, canActivate: [LocalizationGuard],data:{title:'Step1.Title'}},
    {path: 'step-1/clone/:clone', component: StepOneComponent, canActivate: [LocalizationGuard],data:{title:'Step1.Title'}},
    {path: 'step-1/:newlyRegistered', component: StepOneComponent, canActivate: [LocalizationGuard],data:{title:'Step1.Title'}},
    {path: 'step-2', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'step-2/:newlyRegistered', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'step-2/section/:sectionName', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'step-2/section/:sectionName/:newlyRegistered', component: StepTwoComponent, canActivate: [LocalizationGuard],data:{title:'Step2.Title'}},
    {path: 'step-3', component: StepThreeComponent, canActivate: [LocalizationGuard],data:{title:'Step3.Title'}},
    {path: 'step-3/:newlyRegistered', component: StepThreeComponent, canActivate: [LocalizationGuard],data:{title:'Step3.Title'}},
    {path: '*', component: HomepageComponent, canActivate: [LocalizationGuard], data:{title:'Homepage.Title'}},
    {path: '', component: HomepageComponent, canActivate: [LocalizationGuard], data:{title:'Homepage.Title'}},

    {path: 'pdf', children: [
      {path: 'pdf-view', component: PdfViewComponent, canActivate: [LocalizationGuard], data:{title:'Step3.Title'}},
    ]},
    {path: 'fr/pdf', children: [
      {path: 'pdf-view', component: PdfViewComponent, canActivate: [LocalizationGuard], data:{title:'Step3.Title'}},
    ]},
    {path: 'read', children: [
      {path: ':id', component: ReadOnlyViewComponent, canActivate: [LocalizationGuard], data:{title:'Homepage.Title'}},
    ]},
    {path: 'fr/read', children: [
      {path: ':id', component: ReadOnlyViewComponent, canActivate: [LocalizationGuard], data:{title:'Homepage.Title'}},
    ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
