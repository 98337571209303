import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {CanActivate, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {LocalizationService} from "../localization.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class LocalizationGuard implements CanActivate {

  constructor(@Inject(LOCALE_ID) public locale: string,
              private translate: TranslateService,
              private localizationService: LocalizationService) {
  }

  canActivate(route: any, state: any): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ((route.url[0] != null && route.url[0].path == 'fr') || (route.parent != null && route.parent.url != null && route.parent.url[0] != null && route.parent.url[0].path == 'fr')) {
      this.translate.use('fr')
      this.translate.currentLang = 'fr'
      this.localizationService.useLanguage('fr')
      this.locale = "fr"
    } else {
      this.translate.use('en')
      this.locale = "en-CA"
      this.translate.currentLang = 'en'
      this.localizationService.useLanguage('en')
    }
    return true;
  }

}
