import {APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LocalizationService} from "./localization.service";

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, TranslateModule.forChild()],
  exports: [TranslateModule]
})

export class LocalizationModule {
  public static forRoot(config: any): ModuleWithProviders<TranslateModule> {
    return {
      ngModule: LocalizationModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initLocalizationService,
          deps: [LocalizationService],
          multi: true
        },
        TranslateService,
        { provide: LOCALE_ID, useValue: 'en' }, // using the initial value
      ]
    };
  }
}

export function initLocalizationService(service: LocalizationService) {
  const result = service.initService()
  return () => result;
}
