import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {QuestionAnswerService} from "../../services/question-answer-service/question-answer.interface";
import {zip} from "rxjs";
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {PublicSecureService} from "../../services/public-secure/public-secure.service.interface";
import {GoogleAnalyticsServiceInterface} from "../../services/google-analytics-service/google-analytics.interface";
import {StatusEnum} from "../../enums/status.enum";
import {AnswerModel, ContentModel, mappers, UserPlanModel, UserPlanSectionModel} from "shared-library";
import mapToQuestionModel = mappers.mapToQuestionModel;
import mapToAnswerModel = mappers.mapToAnswerModel;
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'app-buttons-container',
  templateUrl: './buttons-container.component.html',
  styleUrls: ['./buttons-container.component.scss']
})
export class ButtonsContainerComponent implements OnInit {

  @Input() backRoute: string = ''

  @Input() nextButtonText: string = ''
  @Input() nextRoute: string = ''

  @Input() userPlan: UserPlanModel = new UserPlanModel("")
  @Input() stepNumber: number = 0

  @Input() userPlanSections: UserPlanSectionModel[] = []

  @Input() blueBoxContent: ContentModel

  @Output() resetClickedEmitter: EventEmitter<any> = new EventEmitter()
  @Output() validationErrorEmitter: EventEmitter<any> = new EventEmitter()
  @Output() saveSectionBeforeLogin: EventEmitter<any> = new EventEmitter()

  currentLang: string = ''
  isLoggedIn = false
  invalidSections = false

  constructor(public translateService: TranslateService,
              public router: Router,
              @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
              @Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('QuestionAnswerService') public questionAnswerService: QuestionAnswerService,
              @Inject('AuthService') public authService: AuthService,
              @Inject('PublicSecureService') private publicSecureService: PublicSecureService,
              private cookieService: CookieService) {
    this.currentLang = translateService.currentLang

    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    this.isLoggedIn =  this.authService.isUserLoggedIn();
    this.authService.updateLoggedInEmail.subscribe(name => {
      this.isLoggedIn = this.authService.isUserLoggedIn();
    });
  }

  backClicked() {
    let backUrl = this.currentLang == 'fr' ? '/fr' + this.backRoute : this.backRoute
    this.router.navigate([backUrl])
  }

  resetClicked() {
    this.resetClickedEmitter.emit()
  }

  nextClicked(toLogin: boolean = false) {
    let combinedErrors: AnswerModel[] = []
    let badData = false

    if (this.needsCompletion(toLogin)) {
      this.invalidSections = true
      this.validationErrorEmitter.emit(true)
      return
    }

    let toZip = [];
    if (this.userPlan.sections != null) {
      this.userPlan.sections.sort((a, b) => {
        return a.sectionDisplayOrderNumber > b.sectionDisplayOrderNumber ? 1 : -1
      })

    this.userPlan.sections.forEach(x => {
      let combinedAnswers: AnswerModel[] = []
      x.questions.forEach(y => {

        y = mapToQuestionModel(y)
        let validation = y.hasValidAnswer(this.currentLang)
        if (y.displayText != '' && validation[0] == false && document.getElementById(<string>y.displayText)) {
          combinedErrors.push(validation[1])
          badData = true
        }

        if (y.answers != null && y.answers.length > 0) {
          y.answers.forEach(z => {
            if (z.id != null && z.id.length > 0) combinedAnswers.push(mapToAnswerModel(z))
          })
        }
      })
      if (this.stepNumber == 1) toZip.push(this.questionAnswerService.saveQuestionAnswers(this.userPlan.id, combinedAnswers, x.id))
    })

    }
    if (!badData) {
      this.userPlan.sections = []
      if (this.stepNumber == 1) {
        this.userPlanService.saveUserPlan(this.userPlan).subscribe(() => {
          zip(...toZip).subscribe(() => {
            toLogin ? this.login() : this.navigateAway()
          })
          if (toZip.length == 0) toLogin ? this.login() : this.navigateAway()
        })
      } else if (this.stepNumber == 2 && !toLogin) {
        this.userPlan.isCompleted = true
        this.userPlanService.saveUserPlan(this.userPlan).subscribe(() => {
          this.navigateAway()
        })
      } else if (this.stepNumber == 2 && toLogin) this.saveSectionBeforeLogin.emit(true)
      else toLogin ? this.login() : this.navigateAway()
    } else {
      this.validationErrorEmitter.emit(combinedErrors)
    }
  }

  navigateAway() {
    let nextUrl = this.currentLang == 'fr' ? '/fr' + this.nextRoute : this.nextRoute
    this.router.navigate([nextUrl])
  }

  needsCompletion(toLogin: boolean = false) {
    return this.stepNumber == 2 && !toLogin && (this.userPlanSections == null || this.userPlanSections == undefined || this.userPlanSections.length == 0 || this.userPlanSections.filter(x => x.sectionCompletionStatuses == null || x.sectionCompletionStatuses.filter(x => x.sectionStatusId == StatusEnum.COMPLETED).length == 0).length > 0)
  }

  login() {
    this.googleAnalyticsService
      .eventEmitter("login-initiated", "Create account to save", "Navigate to Public Secure", "click", this.router.url);
    var sourceUrl=this.router.url + '/true';

      this.cookieService.put("lastUri",sourceUrl);

    this.authService.login(this.router.url + '/true', this.translateService.currentLang)
  }
}
