import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {PublicSecureService} from "../../services/public-secure/public-secure.service.interface";
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {GoogleAnalyticsServiceInterface} from "../../services/google-analytics-service/google-analytics.interface";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import {ContentDisplayUtil, ContentModel} from "shared-library";
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  userPlanId: string | null = ""
  currentLang: string = ''

  pageContent: ContentModel[] = []
  contentDisplayUtil = ContentDisplayUtil

  constructor(@Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
              @Inject('PublicSecureService') private publicSecureService: PublicSecureService,
              @Inject('AuthService') public authService: AuthService,
              @Inject('ContentService') public contentService: ContentService,
              public translateService: TranslateService,
              public router: Router,
              public route: ActivatedRoute,
              private cookieService: CookieService) {

    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    this.userPlanId = this.cookieService.get("userPlanId");
    window.scrollTo(0, 0)

    this.contentService.getContentForPage('Homepage', this.currentLang == 'fr', '').subscribe(content => {
      this.pageContent = content
    })
  }

  goToStep1() {
    let path = this.translateService.currentLang == 'en' ? '/step-1' : '/fr/etape-1'


      this.userPlanService.createUserPlan().subscribe(userPlanId => {
        this.cookieService.put("userPlanId", userPlanId)
        this.router.navigate([path], {relativeTo: this.route})
      })


  }

  goToMyAccount() {
    this.router.navigate([this.currentLang == 'en' ? 'my-account' : 'fr/mon-dossier']);

  }

  login() {
    this
      .googleAnalyticsService
      .eventEmitter("login-initiated", "Register to develop safety plan", "Navigate to Public Secure", "click", this.router.url);
    //To be replaced with call to backend
    //encodedRedirectUrl = encodeURIComponent(encodedRedirectUrl);
    var sourceUrl = this.router.url;

    this.cookieService.put("lastUri",sourceUrl);


    this.authService.login(this.router.url,this.translateService.currentLang)
  }

  refreshtoken() {
    this.publicSecureService.getRefreshToken()
      .subscribe(data => {
    //    console.log('getRefreshToken:' + data.headers);
      });
  }
}
