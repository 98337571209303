import {AfterViewChecked, ChangeDetectorRef, Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {LoggingService} from "./services/app-insight-logging/logging.service.interface";
import {SpinnerService} from "./services/spinner-service/spinner.service";
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {environment} from "../environments/environment";
import {GoogleAnalyticsServiceInterface} from "./services/google-analytics-service/google-analytics.interface";
import {Title} from "@angular/platform-browser";
import {LocalizationService} from "./translation/localization.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ErrorService} from "./services/error_service/error.service";
import {RestfulUserInfoService} from "./services/user-info-service/restful-user-info.service";
import {AuthService} from "./services/auth.service/auth.service.interface";
import {ErrorDialogComponent, UserModel} from "shared-library";
import {TranslateService} from "@ngx-translate/core";
import {CookieService} from "ngx-cookie";

declare const gtag: Function;

declare function navigate(url): string;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'safety-planner-client';

  errorMsg: string = ''
  hasCheckedForUserEmail = false

  showHeaderFooter = true

  // Ensure that an instance of the AppInsightLoggingService class is created as soon as the application starts

  constructor(@Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
              @Inject('LoggingService') private appInsightLoggingService: LoggingService,
              @Inject('SpinnerService') public spinnerService: SpinnerService,
              @Inject('ErrorService') public errorHandlerService: ErrorService,
              @Inject('UserInfoService') public userInfoService: RestfulUserInfoService,
              @Inject('AuthService') public authService: AuthService,
              @Inject(LOCALE_ID) public locale: string,
              public localizationService: LocalizationService,
              private router: Router,
              public activatedRoute: ActivatedRoute,
              public titleService: Title,
              private dialog: MatDialog,
              public translate: TranslateService,
              private cookieService: CookieService) {
    this.addGAScript();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
     // console.log('event.urlAfterRedirects:',event.urlAfterRedirects)
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects,
      })

      if (event.urlAfterRedirects.indexOf('/pdf/') > -1){
        this.showHeaderFooter = false
        let isFrench = event.urlAfterRedirects.indexOf('/fr/') > -1
        this.locale = isFrench ? 'fr' : 'en'
        this.translate.currentLang = this.locale
      } else this.showHeaderFooter = true
      /** END */

    })

    this.errorHandlerService.showError.subscribe(error => {

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;

      dialogConfig.data =
        {
          errorMsg: error
        };
      this.dialog.open(ErrorDialogComponent, dialogConfig);
    })
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
        var rt = this.getChild(this.activatedRoute)

        rt.data.subscribe(data => {
          this.titleService.setTitle(this.localizationService.translate(data.title))
        })
        this.setUserloggedIn()
    })
  }

    setUserEmailInLocalStorage() {
        var email = this.authService.getUserEmail();
        if ((email == null || email.length == 0 || email == undefined) && this.authService.isUserLoggedIn() === true) {
            this.userInfoService.getUserInfo().subscribe(data => {
                let user = data != null && data.value != null ? JSON.parse(data.value) : null
                if (user) {
                    this.authService.setUserEmail(user.Email)
                    this.hasCheckedForUserEmail = true
                }
            }, err => {
                this.hasCheckedForUserEmail = true
                this.spinnerService.hide()
            })
        } else {
            this.hasCheckedForUserEmail = true
        }
    }

    setUserloggedIn() {
        this.userInfoService.isAuthenticated().subscribe(data => {
            let authenticated = data.value.toLowerCase() == "true"
            this.authService.setIsAuthenticated(authenticated)
            this.setUserEmailInLocalStorage()
            if (authenticated) this.authService.isLoggedIn.emit()
        })
    }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    /** Add Google Analytics Script Dynamically */
    addGAScript() {
        let gtagScript: HTMLScriptElement = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingId;
        document.head.prepend(gtagScript);
        /** Disable automatic page view hit to fix duplicate page view count  **/
        gtag('config', environment.gaTrackingId, {send_page_view: false});
    }
}
