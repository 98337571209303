import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {FeedbackService} from "./feedback.service.interface";
import {SurveyFeedbackModel} from "shared-library";

@Injectable({
  providedIn: 'root'
})
export class RestfulFeedbackService implements FeedbackService {

  constructor(private httpClient: HttpClient) {
  }

  saveFeedback(feedback:SurveyFeedbackModel): Observable<any> {
    return this.httpClient.post<string>(environment.apiBaseUrl + `feedback`, feedback)
  }
}
