import {ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {QuestionAnswerService} from "../../services/question-answer-service/question-answer.interface";
import {
  ContentDisplayUtil,
  InputTypeModel,
  mappers,
  QuestionModel,
  SectionModel,
  UserPlanModel,
  UserPlanSectionModel
} from "shared-library";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from 'html2canvas';
import * as htmlToPdfmake from 'html-to-pdfmake'
import {DatePipe} from "@angular/common";
import {zip} from "rxjs";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import mapToUserPlanSectionModels = mappers.mapToUserPlanSectionModels;
import {GoogleAnalyticsServiceInterface} from "../../services/google-analytics-service/google-analytics.interface";
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'app-pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit {

  userPlanId: string
  userPlan: UserPlanModel

  userPlanSections: UserPlanSectionModel[] = []

  currentLang: string

  pageContents = []
  pageSize

  localPDFMake

  @ViewChild('logo') logo: ElementRef;
  @ViewChild('titles') titles: ElementRef;
  @ViewChild('border') border: ElementRef;
  @ViewChild('body') body: ElementRef;

  contentDisplayUtil = ContentDisplayUtil

  datePipe: DatePipe = new DatePipe('en-CA')
  spin = true
  shortenedBusinessName: string

  constructor(@Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('QuestionAnswerService') public questionAnswerService: QuestionAnswerService,
              @Inject('ContentService') public contentService: ContentService,
              @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
              @Inject(LOCALE_ID) public locale: string,
              public translateService: TranslateService,
              public route: ActivatedRoute,
              public router: Router,
              private cdr: ChangeDetectorRef,
              private cookieService: CookieService) {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.localPDFMake = Object.assign({}, pdfMake)
    this.localPDFMake.vfs = pdfFonts.pdfMake.vfs;

    this.currentLang = translateService.currentLang

    this.translateService.onLangChange.subscribe(lang => {
      this.locale = lang.lang
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.userPlanId = this.cookieService.get("userPlanId");
    if (this.userPlanId == null) this.userPlanId = this.cookieService.get('sharedId')

    if (this.userPlanId) {
      this.userPlanService.getUserPlanById(this.userPlanId).subscribe(userPlan => {
        this.userPlan = userPlan

        zip(this.contentService.getContentForPage('Step3', this.currentLang == 'fr', this.userPlanId), this.questionAnswerService.getSectionsAndQuestionsByStepAndPlanId(2, this.userPlanId, true)).subscribe(data => {
          this.userPlanSections = mapToUserPlanSectionModels(data[1])

          let importantNotesUserPlanSection = new UserPlanSectionModel()
          let importantNotes = data[0].filter(x => x.contentCode == 'ImportantNotes')
          if (importantNotes != null && importantNotes.length > 0) {
            let note = importantNotes[0]
            importantNotesUserPlanSection.section = new SectionModel('Important Notes', '', '', note.title, note.title, 0, [
              new QuestionModel('important-notes', [], new InputTypeModel('importantNotes', 'Paragraph Body'), false, [], [], null, null, 0, note.contentHTML, note.contentHTML)
            ], true)

            this.userPlanSections.unshift(importantNotesUserPlanSection)
          }

          this.addContentToPages(0)

          this.cdr.detectChanges()

          this.sendToPdf()
          this.googleAnalyticsService.eventEmitter("pdf-downloaded", "download", "User Plan PDF Downloaded", "click", this.userPlanId)
        })
      })
    } else this.router.navigate([this.currentLang == 'fr' ? '/fr' : '/'])
  }

  getStackedContent(elementStack): any[] {
    let stack = []

    elementStack.forEach(x => {
      if (x.stack == undefined) stack.push(x)
      else stack.push(...this.getStackedContent(x.stack))
    })
    return stack;
  }

  async sendToPdf() {
    // @ts-ignore
    let result = await zip(html2canvas(document.getElementsByClassName("tombstone").item(0), {scale: 2, letterRendering: true}), html2canvas(document.getElementsByClassName("footer").item(0), {scale: 2})).toPromise()
    const tombstoneImg = result[0].toDataURL('image/jpeg', 4.0)
    const footerImg = result[1].toDataURL('image/jpeg', 4.0)

    let safetyPlans = document.querySelectorAll('.safetyPlan')
    let content = []
    let first = true

    safetyPlans.forEach(x => {
      let safetyPlanContent = htmlToPdfmake(x.outerHTML)
      let contentStack = this.getStackedContent(safetyPlanContent)

      let parsedContent = []

      for (let i = 0; i < contentStack.length; i++) {

        //parse out the columns between the images and their sibling
        //if is chevron image, column only encompasses next back of text
        //if is checkmark, column may encompass next bit of text plus next pair of checkmark + text
        if (contentStack[i].nodeName == 'IMG' && contentStack[i].style.indexOf('chevron') > -1) {
          parsedContent.push({columns: [contentStack[i], contentStack[i + 1]], marginLeft: 14})
          i++
        } else if (contentStack[i].nodeName == 'IMG' && contentStack[i].style.indexOf('checkmark') > -1) {
          contentStack[i].margin = [2, 2, 0, 8]
          contentStack[i + 1].margin = [2, 0, 0, 8]
          if ((i + 2) < contentStack.length && contentStack[i + 2].nodeName == 'IMG' && contentStack[i + 2].style.indexOf('checkmark') > -1) {
            contentStack[i + 2].margin = [2, 2, 0, 8]
            contentStack[i + 3].margin = [2, 0, 0, 8]
            parsedContent.push({
              columns: [contentStack[i], contentStack[i + 1], contentStack[i + 2], contentStack[i + 3]],
              marginLeft: 30
            })
            i = i + 3
          } else {
            parsedContent.push({columns: [contentStack[i], contentStack[i + 1]], marginLeft: 30})
            i++
          }
        } else if (contentStack[i].nodeName == 'H4') {
          contentStack[i].marginLeft = 14
          contentStack[i].marginBottom = 14
          parsedContent.push(contentStack[i], {
            canvas: [
              {type: 'line', x1: 5, y1: -7, x2: 545, y2: -7, lineWidth: 0.5, lineColor: '#1A1A1A'}
            ]
          })
        } else if (contentStack[i].nodeName == 'UL') {
          contentStack[i].marginLeft = 50
          contentStack[i].fontSize = 9
          contentStack[i].bold = false
          contentStack[i].ul.forEach(x => {
            x.fontSize = 9
            if (x.nodeName == 'LI' && x.stack != null) {
              x.stack.forEach(y => {
                y.fontSize = 9
                y.marginTop = 8
                if (y.nodeName == 'UL' && y.ul != null) {
                  y.ul.forEach(z => {
                    z.fontSize = 9
                    if (z.text.constructor.name == 'Array') {
                      z.text.forEach(z2 => z2.fontSize = 9)
                    }
                  })
                }
              })
            }
          })
          parsedContent.push(contentStack[i])
        } else {
          parsedContent.push(contentStack[i])
        }
      }

      first ? content.push({
        canvas: [{
          type: 'line',
          x1: -10,
          y1: -8,
          x2: 560,
          y2: -8,
          lineWidth: 0.5,
          lineColor: '#1A1A1A'
        }], marginTop: 5
      }) : content.push({
        canvas: [{
          type: 'line',
          x1: -10,
          y1: -8,
          x2: 560,
          y2: -8,
          lineWidth: 0.5,
          lineColor: '#1A1A1A'
        }], marginTop: 5, marginBottom: 5, pageBreak: 'before'
      })
      content.push({image: tombstoneImg, width: 550})
      first = false
      content.push(...parsedContent)
    })

    let docDefinition = this.getDocDefinition(footerImg, content)
    const fileName = this.userPlan.businessName + (this.currentLang == 'fr' ? " - plan de sécurité - " : " - Safety Plan - ") + this.datePipe.transform(Date.now(), 'MMMM dd, yyyy', this.currentLang == 'fr' ? 'fr-CA' : 'en-CA') + ".pdf"

    let pdfMaker = this.localPDFMake.createPdf(docDefinition)
    pdfMaker.download(fileName)
    this.spin = false
  }

  getDocDefinition(footerImg, content) {
    return {
      pageSize: 'A4',
      pageMargins: [25, 50, 25, 100],
      pageOrientation: 'portrait',
      header:
        function (currentPage, pageCount, pageSize) {
          if (document.querySelectorAll('.header')[currentPage - 1] == undefined) return null

          let headerNodes = htmlToPdfmake(document.querySelectorAll('.header')[currentPage - 1].outerHTML)[0].stack
          headerNodes[0].stack[0].bold = false
          headerNodes[0].width = 470
          return {
            columns: headerNodes, margin: [25, 20, 15, 10], defaultStyle: {
              h1: {
                bold: false
              },
            }
          }
        },
      footer: {image: footerImg, width: 570, margin: [15, 20, 15, 0]},
      content: content,
      defaultStyle: {
        columnGap: 10,
        h3: {
          marginBottom: 14
        },
        h4: {
          marginLeft: 20
        }
      }
    };
  }

  addContentToPages(curPage) {
    if (this.userPlanSections.length > 0) {

      //Assume current page to fill in based on given param and create a storage array for its future content
      if (this.pageContents.length < (curPage + 1)) this.pageContents.push([])
      let curPageContents = this.pageContents[this.pageContents.length - 1]
      this.cdr.detectChanges()

      //determine space available for content
      if (!this.pageSize) {
        // @ts-ignore
        let fullContentSize = document.querySelector('.content').offsetHeight
        // @ts-ignore
        let headerHeight = document.querySelector('.header').offsetHeight + Number.parseInt(document.querySelector('.header').style.marginTop.split('px')[0]) + Number.parseInt(document.querySelector('.header').style.marginBottom.split('px')[0])
        // @ts-ignore
        let tombstoneHeight = document.querySelector('.tombstone').offsetHeight + Number.parseInt(document.querySelector('.tombstone').style.marginTop.split('px')[0]) + Number.parseInt(document.querySelector('.tombstone').style.marginBottom.split('px')[0])
        // @ts-ignore
        let footerHeight = document.querySelector('.footer').offsetHeight + Number.parseInt(document.querySelector('.footer').style.marginTop.split('px')[0]) + Number.parseInt(document.querySelector('.footer').style.marginBottom.split('px')[0])

        this.pageSize = fullContentSize - (headerHeight + tombstoneHeight + footerHeight + 20)
      }

      //Remove all but one question from section (or just leave header if no questions available)
      let questions = Object.assign([], this.userPlanSections[0].section.questions).filter(x => ['paragraph body', 'paragraph header', 'paragraph', 'bullet list'].indexOf(x.inputType.inputTypeName.toLowerCase()) > -1 || x.hasAnyAnswer())

      this.userPlanSections[0].section.questions = []
      //if (questions.length > 0) this.userPlanSections[0].section.questions.push(questions.shift())
   
      if (questions.length === 0) {
        this.userPlanSections.shift(); // Remove the section from the processing stack
        this.addContentToPages(curPage); // Continue with the next section on the same page
        return;
      }      

      //Add section heading (plus first question if available)
      curPageContents.push(this.userPlanSections[0])
      this.cdr.detectChanges()

      //if the addition of that section + potentially first question over extends the page, remove from current page and re-add the sections questions
      // @ts-ignore
      if ((document.querySelectorAll('.safetyPlan')[curPage].offsetHeight) > this.pageSize) {
        this.userPlanSections[0].section.questions.push(...questions)
        curPageContents.pop()
        this.cdr.detectChanges()

        this.addContentToPages((curPage + 1))
      } else {
        //while there are still questions to add to the section, check to see if the addition of the question will push us over the page limit
        // @ts-ignore
        while (questions.length > 0 && (document.querySelectorAll('.safetyPlan')[curPage].offsetHeight) < this.pageSize) {
          this.userPlanSections[0].section.questions.push(questions.shift())
          this.cdr.detectChanges()
        }

        //if we did manage to go overboard with the addition of a question/answer, remove that last question and create a new section for the remaining questions
        // @ts-ignore
        if ((document.querySelectorAll('.safetyPlan')[curPage].offsetHeight) > this.pageSize) {
          let remainingQuestions = [this.userPlanSections[0].section.questions.pop(), ...questions]

          //Lose the reference to the original array item, lest we overwrite its name and questions
          let partialUserSection = new UserPlanSectionModel(null, null, new SectionModel('', null, null, this.userPlanSections[0].section.sectionName, this.userPlanSections[0].section.sectionNameFr, 0, this.userPlanSections[0].section.questions, false))
          curPageContents.pop()
          curPageContents.push(partialUserSection)

          //Amend new section with continuation marker plus the remaining questions that did not fit in the orig section
          let splitUserSection = Object.assign({}, this.userPlanSections[0])
          splitUserSection.section.questions = remainingQuestions
          splitUserSection.section.sectionName += ' - Continued'
          splitUserSection.section.sectionNameFr += ' - continuée'
          this.cdr.detectChanges()

          //Remove orig section from processing stack, but add split section to the top
          this.userPlanSections.shift()
          this.userPlanSections.unshift(splitUserSection)
          this.addContentToPages((curPage + 1))
        }

        //if we fit the entire section on the page, pop from the userPlanSection stack and carry on with the next section on the same page
        else {
          this.userPlanSections.shift()
          this.addContentToPages(curPage)
        }
      }
    }
  }

  public parseEmptySpacesForCanvasRendering(text: string, maxCharLength: number){
    let words = text.split(' ')
    let result = ""
    words.forEach(x => {
      if (x.length > maxCharLength) {
        let hyphenatedWord = ''
        for (let i = 0; i < Math.max(x.length/maxCharLength); i++){
          hyphenatedWord += `</br>${x.substring(i*maxCharLength, Math.min((i+1)*maxCharLength, x.length))}-`
        }
        x = hyphenatedWord.substring(0, hyphenatedWord.length-1)
      }
      result += x + ' '
    })
    if (result.indexOf('</br>') == 0) result = result.substr(5)
    return result.substr(0, result.length -1)
  }
}



