import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {ContentService} from "./content.sevice.interface";
import {ContentModel} from "shared-library";

@Injectable({
  providedIn: 'root'
})
export class RestfulContentService implements ContentService {
  constructor(private httpClient: HttpClient) {
  }

  getContentForPage(pageName: string, isFrench: boolean, userPlanId: string): Observable<ContentModel[]> {
    if (userPlanId == null || userPlanId == undefined || userPlanId.length == 0) userPlanId = 'null'
    return this.httpClient.get<any>(environment.apiBaseUrl + `content/${pageName}/${isFrench}/${userPlanId}`);
  }
}
