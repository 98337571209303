import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import {Subscription} from "rxjs";
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {IdleTimeoutService} from "../../services/idle-timeout-service/idle-timeout.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {GoogleAnalyticsService} from "../../services/google-analytics-service/google-analytics.service";
import { UserInfoService } from "../../services/user-info-service/user-info.sevice.interface";
import {ConfirmationCloseDialogComponent} from "shared-library";

@Component({
  selector: 'session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {
  private _idleTimerSubscription: Subscription;
  currentLang: string = ''
  dialogRef

  constructor(public translateService: TranslateService,
              public changeRef: ChangeDetectorRef,
              public dialog: MatDialog,
              @Inject('IdleTimeoutService') public idleTimeoutSvc: IdleTimeoutService,
              @Inject('AuthService') public authService: AuthService,
              @Inject('UserInfoService') public userInfoService: UserInfoService,
              @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsService) {
    this.currentLang = translateService.currentLang
    this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(() => {
    })
  }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn() != null && this.authService.isUserLoggedIn() && this.authService.isSessionExpiryValid()) {
      this.evaluatingTimer();
    }

    this.authService.newExpirationSet.subscribe(isNew => {
      if (isNew) {
        this.evaluatingTimer();
      } else
        this.idleTimeoutSvc.stopTimer();
    });
  }

  private evaluatingTimer() {
    this.idleTimeoutSvc.startTimer();
    this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(res => {

      var accessAllowed = this.authService.isSessionExpiryValid();
      if (accessAllowed == true) {
        // @ts-ignore
        var minutesToExpire = this.authService.sessionToExpireWithinTime();

        if (minutesToExpire <= 10)  //expire within 10 minutes
        {
          if (minutesToExpire == null || minutesToExpire <= 0) {
            minutesToExpire = 0;
            this.showDialogForExtendSession(minutesToExpire);
          } else {
            this.showDialogForExtendSession(minutesToExpire);
          }
        }
      }
    });
  }

  private logUserOut() {
    this.googleAnalyticsService.eventEmitterWithUserEmail('Session-TimeOut', 'Session', 'timeout', null, null, this.authService.getUserEmail())
    try {
      this.dialog.closeAll();
    } catch (e) {
    }
    //redirect to logout page
    this.idleTimeoutSvc.stopTimer();
    this.authService.gotoLogout();
  }

  private showDialogForExtendSession(mins: number) {
    try {
      this.dialog.closeAll();
    } catch (e) {
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;

    dialogConfig.data =
      {
        mins: mins,
        inactive: 30 - mins
      };

    this.dialogRef = this.dialog.open(ConfirmationCloseDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe
    (
      data => {
        if (data === true) {
          this.idleTimeoutSvc.resetTimer();
          this.changeRef.markForCheck();
          this.userInfoService.isAuthenticated().subscribe(data => {
          });
        } else if (data === false) {
          this.changeRef.markForCheck()
          this.logUserOut();
        }
      }
    );
  }
}
