import {EventEmitter, Inject, Injectable, Output} from '@angular/core';
import {AuthService} from "./auth.service.interface";
import {TranslateService} from "@ngx-translate/core";
import {PublicSecureService} from "../public-secure/public-secure.service.interface";
import {GoogleAnalyticsService} from "../google-analytics-service/google-analytics.service";
import {UserInfoService} from "../user-info-service/user-info.sevice.interface";
import {SpinnerService} from "../spinner-service/spinner.service";
import {CookieService} from "ngx-cookie";

declare function navigate(url): string;

@Injectable({
  providedIn: 'root'
})

export class SafetyPlannerAuthService implements AuthService {
  @Output() updateLoggedInEmail: EventEmitter<any> = new EventEmitter();
  @Output() newExpirationSet: EventEmitter<boolean> = new EventEmitter();
  @Output() isLoggedIn: EventEmitter<any> = new EventEmitter();

  constructor(public translateService: TranslateService,
              @Inject('UserInfoService') private userInfoService: UserInfoService,
              @Inject('PublicSecureService') private publicSecureService: PublicSecureService,
              @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsService,
              @Inject('SpinnerService') public spinnerService: SpinnerService,
              private cookieService: CookieService) {
  }

  private userEmail: string;
  private isUserSignedIn: boolean = false;
  private cookieExpiry: number

  getUserEmail(): string {
    return this.userEmail;
  }

  setUserEmail(email: string) {
    if (this.getUserEmail() != email) {
      this.userEmail = email;
      this.updateLoggedInEmail.emit(email)
    }
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isUserSignedIn = isAuthenticated;
  }

  setSessionExpiry(expiry: number) {
    this.cookieExpiry = expiry
    this.newExpirationSet.emit(true)
  }

  isUserLoggedIn(): boolean {
    return this.isUserSignedIn;
  }

  login(sourceUrl: string, lang: string) {



    let state = {sourceUrl: encodeURIComponent(sourceUrl)};
    navigate("https://" + window.location.host + "/login?lang=" + lang + "&state=" + JSON.stringify(state))
  }

  sessionToExpireWithinTime(): number {
    var now = new Date()
    var utcDate = (Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())) / 1000

    return Math.floor((this.cookieExpiry - utcDate) / 60)
  }

  gotoLogout() {
    this.googleAnalyticsService.eventEmitterWithUserEmail('logout', 'Authentication', 'logout', null, null, this.getUserEmail())
    this.newExpirationSet.emit(false)
    this.updateLoggedInEmail.emit("")
    localStorage.clear();
    this.cookieService.removeAll();
    let lang = this.translateService.currentLang == 'fr' ? 'fr' : 'en';
    navigate("https://" + window.location.host + "/signout?lang=" + lang)// "https://localhost:4200/signout?lang="+lang
  }

  isSessionExpiryValid() {
    var now = new Date()
    var utcDate = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())
    return this.cookieExpiry != null && this.cookieExpiry != undefined && this.cookieExpiry > (utcDate / 1000);
  }

  isSessionExpiryNull(){
    return this.cookieExpiry == null || this.cookieExpiry == undefined
  }

  gotoEditProfile() {
    let redirctUrl = '';
    this.publicSecureService.getUserProfileUrl()
      .subscribe(data => {

        redirctUrl = data?.value;
        let status = data?.message;

        if (status != null && status.toLowerCase() == 'success')
          navigate(redirctUrl);
      });
  }

  callServiceForNewToken() {
    return this.publicSecureService.getRefreshToken();
  }
}
