import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {

  @Input() isDarkTheme: boolean = false
  @Input() title: string = ''
  @Input() stepNumber: number
  @Output() loginClicked: EventEmitter<any> = new EventEmitter

  isLoggedIn: boolean = false

  constructor(@Inject(LOCALE_ID) public locale: string,
              @Inject('AuthService') private authService: AuthService,
              public route: ActivatedRoute,
              public router: Router) {

    this.authService.isLoggedIn.subscribe(name => {
      this.isLoggedIn = this.authService.isUserLoggedIn();
    });
    router.events.subscribe(() => {
      this.isLoggedIn = this.authService.isUserLoggedIn();
    })
  }

  ngOnInit(): void {
    this.isLoggedIn =  this.authService.isUserLoggedIn();
  }
}
