import {EventEmitter, Injectable} from '@angular/core';
import {ErrorServiceInterface} from "./error.interface";


@Injectable()
export class ErrorService implements ErrorServiceInterface{

  public showError: EventEmitter<string> = new EventEmitter()

  constructor() {}

}
