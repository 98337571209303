<app-steps-banner [activeStep]="2" (loginClicked)="loginClicked()"></app-steps-banner>

<div class="ontario-row ontario-margin-top-40-!">


    <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9">
        <ng-container *ngFor="let content of [contentDisplayUtil.getContentForPage(pageContent, 'TitleSubtext')]">
            <h2
                    class="step-title ontario-margin-bottom-8-!" [innerHTML]="content.title"></h2>
            <div class="step-subtext ontario-margin-bottom-24-!" [innerHTML]="content.contentHTML"></div>
        </ng-container>
        <app-plan-tombstone #tombstone [userPlan]="userPlan" [includeDates]="true"></app-plan-tombstone>

        <!--    <h3 class="section-title border-bottom ontario-padding-bottom-4-! ontario-margin-bottom-24-!">{{'Step2.WorkplaceSafetyPlan' | translate}}</h3>-->

        <app-success-banner *ngIf="newlyRegistered"
                            [successTitle]="('Profile.YourPlanHasBeenSavedToYourAccount' | translate)"
                            [buttonText]="('Profile.ViewMyAccount' | translate)"></app-success-banner>

        <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9">
            <div class="ontario-row">

                <reset-confirmation #resetConfirmation *ngIf="showResetConfirmation"
                                    (closeReset)="showResetConfirmation = false"
                                    (toggleInputs)="toggleFirst()"
                                    [stepNumber]="2"
                                    [userPlanSections]="userPlanSections"
                                    [userPlan]="userPlan"></reset-confirmation>
            </div>
        </div>

    </div>

    <app-grey-box [content]="contentDisplayUtil.getContentForPage(pageContent, 'SidebarContent')"></app-grey-box>

    <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9 ontario-padding-bottom-40-!">
        <div id="accordion-instructions"
             [innerHTML]="'Step2.PleaseGoThroughEachSectionBelowAndChooseYourControlMeasures' | translate"></div>

        <div class="accordion-container" *ngFor="let userSection of userPlanSections; let i = index">

            <div id="accordion-title-{{i}}" (click)="togglePanel(i)" [class.active]="openArray[i]"
                 class="accordion-title">
                <img *ngIf="openArray[i]" [alt]="('Step2.OpenChevronImage' | translate)"
                     class="chevron-img"
                     src="assets/images/blue-circle-up-chevron.svg" [tabIndex]="0" (keyup.enter)="togglePanel(i)">
                <img *ngIf="!openArray[i]" [alt]="('Step2.ClosedChevronImage' | translate)"
                     class="chevron-img"
                     [class.down-blue-arrow]="openArray[i]"
                     src="assets/images/gray-circle-down-chevron.svg" [tabIndex]="0" (keyup.enter)="togglePanel(i)">
                <h4 class="section-title"
                    *ngIf="userSection.section.sectionName != null && userSection.section.sectionName.length > 0">{{currentLang == 'en' ? userSection.section.sectionName : userSection.section.sectionNameFr}}</h4>
                <img *ngIf="checkCompletionStatus(userSection.section.id, i) && !openArray[i]"
                     [alt]="('General.CompletedIconAltText' | translate)"
                     src="assets/images/checkmark-in-blue-circle.svg">
                <img *ngIf="checkCompletionStatus(userSection.section.id, i) && openArray[i]"
                     [alt]="('General.CompletedIconAltText' | translate)"
                     src="assets/images/checkmark-in-white-circle.svg">
                <img *ngIf="!checkCompletionStatus(userSection.section.id, i) && validationTriggered && !openArray[i]"
                     [alt]="('General.IncompleteIconAltText' | translate)"
                     src="assets/images/red-with-white-exclamation.png">
                <img *ngIf="!checkCompletionStatus(userSection.section.id, i) && validationTriggered && openArray[i]"
                     [alt]="('General.IncompleteIconAltText' | translate)"
                     src="assets/images/white-with-red-exclamation.png">
            </div>
            <div *ngIf="openArray[i]" id="accordion-content-{{i}}" class="accordion-content">
                <app-input-type-loop #inputs *ngIf="userSection.section.questions != null"
                                     [questions]="userSection.section.questions"
                                     [stepNumber]="2" (completeClicked)="completeNext($event, i)"
                                     [isConfirm]="userSection.section.sectionName.toLowerCase() == 'important notes'"
                                     [finalSectionOnPage]="i == userPlanSections.length - 1"
                                     [userPlanId]="userPlan.id"></app-input-type-loop>

                <app-error-banner *ngIf="showRequirementError && !userSection.section.meetsSaveRequirements()"
                                  [sectionMandatory]="true"
                                  [errorString]="((userSection.section.sectionName.toLowerCase() != 'physical distancing and separation' ? ('Step2.SectionErrorPrefix' | translate) + (currentLang == 'fr' ? userSection.section.sectionNameFr.toLowerCase() : userSection.section.sectionName.toLowerCase()) + '.' : ('Step2.PhysicalDistanceError' | translate)) + ('Step2.SectionErrorSuffix' | translate))"></app-error-banner>
            </div>
        </div>
    </div>


    <div id="buttons-column" class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9 ontario-padding-bottom-40-!">
        <app-buttons-container #buttons
                               [backRoute]="currentLang == 'en' ? '/step-1' : '/etape-1'"
                               [userPlan]="userPlan"
                               [stepNumber]="2"
                               [blueBoxContent]="contentDisplayUtil.getContentForPage(pageContent, 'BlueBox')"
                               [userPlanSections]="userPlanSections"
                               (resetClickedEmitter)="showReset()"
                               (saveSectionBeforeLogin)="saveSectionBeforeLogin()"
                               (validationErrorEmitter)="validationTriggered = $event"
                               [nextButtonText]="'Step2.GetWorkplaceSafetyPlan' | translate"
                               [nextRoute]="currentLang == 'en' ? '/step-3' : '/etape-3'"
        ></app-buttons-container>
    </div>
    <app-feedback-panel [userPlan]="userPlan" [pageName]="'Step 2'"></app-feedback-panel>
</div>
