import {Injectable} from '@angular/core';
import {GoogleAnalyticsServiceInterface} from "./google-analytics.interface";
import {environment} from "../../../environments/environment";

declare function logAnalytics(type, event, params);

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService implements GoogleAnalyticsServiceInterface {

  constructor( ){}

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventSource: string = null){
    logAnalytics('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventSource:eventSource
    })
  }
  public eventEmitterWithUserEmail(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string ,
    eventSource: string ,
    userEmail:string){
    logAnalytics('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction,
      eventSource:eventSource,
      userEmail:userEmail
    })
  }
  public setUserId(userId: string) {
    logAnalytics('config', environment.gaTrackingId, {
      'user_id': userId
    });
  }
}
