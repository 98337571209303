import {ErrorHandler, Inject, Injectable} from '@angular/core';
import {LoggingService} from "../app-insight-logging/logging.service.interface";
import {SpinnerService} from "../spinner-service/spinner.service";
import {ErrorService} from "../error_service/error.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(@Inject('LoggingService') private appInsightLoggingService: LoggingService,
              @Inject('SpinnerService') private spinnerService: SpinnerService,
              @Inject('ErrorService') private errorService: ErrorService) {
    super();
  }

  handleError(error: any) {
    if (!error.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      this.errorService.showError.emit('Error while processing request please retry. if problem persists please contact customer support')
      this.spinnerService.hide()
    }

    this.appInsightLoggingService.logException(error);
  }
}
