import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LocalizationModule} from "./translation/localization.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {LocalizationGuard} from "./translation/guard/localization.guard";
import {HomepageComponent} from './page-components/homepage/homepage.component';
import {PublicSecureAuthComponent} from './page-components/public-secure-auth/public-secure-auth.component';
import {StepOneComponent} from './page-components/step-one/step-one.component';
import {StepsBannerComponent} from './components/steps-banner/steps-banner.component';
import {FormsModule} from "@angular/forms";
import {ButtonsContainerComponent} from './core-components/buttons-container/buttons-container.component';
import {StepTwoComponent} from './page-components/step-two/step-two.component';
import localeFr from '@angular/common/locales/fr-CA'
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData} from "@angular/common";
import {RestfulPublicSecureService} from "./services/public-secure/restful-public-secure.service";
import {RestfulQuestionAnswerService} from "./services/question-answer-service/restful-question-answer.service";
import {RestfulUserPlanService} from "./services/user-plan-service/restful-user-plan.service";
import {AccountComponent} from './core-components/account/account.component';
import {SafetyPlannerAuthService} from "./services/auth.service/safety-planner-auth.service";
import {LoginComponent} from './core-components/login/login.component';
import {HttpClientInterceptor} from "./interceptors/http-client.interceptor";
import {StepThreeComponent} from './page-components/step-three/step-three.component';
import {FeedbackPanelComponent} from './core-components/feedback-panel/feedback-panel.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {SubHeaderComponent} from "./core-components/sub-header/sub-header.component";
import {ProfileComponent} from "./page-components/my-account/profile.component";
import {SessionTimeoutComponent} from "./page-components/session-timeout/session-timeout.component";
import {AppInsightLoggingService} from "./services/app-insight-logging/app-insight-logging.service";
import {GoogleAnalyticsService} from "./services/google-analytics-service/google-analytics.service";
import {ErrorHandlerService} from "./services/error_handler/error-handler.service";
import {IdleTimeoutService} from "./services/idle-timeout-service/idle-timeout.service";
import {SpinnerService} from "./services/spinner-service/spinner.service";
import {RestfulFeedbackService} from "./services/feedback-service/restful-feedback.service";
import {ErrorService} from "./services/error_service/error.service";
import {RestfulUserInfoService} from "./services/user-info-service/restful-user-info.service";
import {RestfulContentService} from "./services/content-service/restful-content.service";
import {SharedLibraryModule} from "shared-library";
import {PdfViewComponent} from './page-components/pdf-view/pdf-view.component';
import {PdfHeaderComponent} from './core-components/pdf-header/pdf-header.component';
import {ReadOnlyViewComponent} from './page-components/read-only-view/read-only-view.component';
import { CookieModule } from 'ngx-cookie';
import {ParameterHashLocationStrategy} from "./interceptors/parameter-hash-location-strategy";

registerLocaleData(localeFr, 'fr-CA')

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    PublicSecureAuthComponent,
    StepOneComponent,
    StepsBannerComponent,
    ButtonsContainerComponent,
    StepTwoComponent,
    AccountComponent,
    LoginComponent,
    StepThreeComponent,
    ProfileComponent,
    SubHeaderComponent,
    SessionTimeoutComponent,
    FeedbackPanelComponent,
    PdfViewComponent,
    PdfHeaderComponent,
    ReadOnlyViewComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    AppRoutingModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    LocalizationModule.forRoot({locale_id: 'en'}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    FontAwesomeModule,
    SharedLibraryModule,
    CookieModule.forRoot()
  ],
  providers: [
    LocalizationGuard,
    Title,
    {provide: LocationStrategy, useClass: ParameterHashLocationStrategy},
    {provide: 'UserInfoService', useClass: RestfulUserInfoService},
    {provide: 'UserPlanService', useClass: RestfulUserPlanService},
    {provide: 'PublicSecureService', useClass: RestfulPublicSecureService},
    {provide: 'AuthService', useClass: SafetyPlannerAuthService},
    {provide: 'QuestionAnswerService', useClass: RestfulQuestionAnswerService},
    {provide: 'LoggingService', useClass: AppInsightLoggingService},
    {provide: 'GoogleAnalyticsService', useClass: GoogleAnalyticsService},
    {provide: 'ErrorService', useClass: ErrorService},
    {provide: 'IdleTimeoutService', useClass: IdleTimeoutService},
    {provide: 'SpinnerService', useClass: SpinnerService},
    {provide: 'ContentService', useClass: RestfulContentService},
    {provide: 'FeedbackService', useClass: RestfulFeedbackService},
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: HTTP_INTERCEPTORS, useClass: HttpClientInterceptor, multi: true}],
  bootstrap: [AppComponent],
  exports: [TranslateModule]
})

export class AppModule {
}
