import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {UserInfoService} from "../../services/user-info-service/user-info.sevice.interface";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import {ContentDisplayUtil, ContentModel, PlanTombstoneComponent, UserModel, UserPlanModel} from "shared-library";
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  faTimes = faTimes
  faCheck = faCheck

  currentLang: string = ''

  contentDisplayUtil = ContentDisplayUtil

  userPlans: UserPlanModel[] = []
  user: UserModel

  showEditCommunication = false
  tempUserModel: UserModel

  @ViewChild('tombstone') tombstone: PlanTombstoneComponent

  pageContent: ContentModel[]

  constructor(@Inject('AuthService') public authService: AuthService,
              @Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('UserInfoService') public userInfoService: UserInfoService,
              @Inject('ContentService') public contentService: ContentService,
              public translateService: TranslateService,
              public router: Router,
              private cookieService: CookieService) {

    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    this.loadData();
  }
  //workaround to handle redirect user to lastUri after user is redirected to my-account from PSE after user use activation link
  loadData(){
    this.userInfoService.isNewUser().subscribe(isNewUser =>{
      if(isNewUser) {
        var lastUri =  this.cookieService.get("lastUri");
        if(lastUri!= null && lastUri.length>0)
        {
          // console.log('profile:ngOnInit:nav to'+lastUri)
          this.cookieService.remove("lastUri");
          this.router.navigateByUrl(lastUri);
        }
        else {
          var userPlanId = this.cookieService.get("userPlanId");
          if (userPlanId != null && userPlanId.length > 0) {
            // if userPlanId exist, Try to save plan for this user then load
            this.userPlanService.assignUserPlanToCurrentUser(userPlanId).subscribe(result => {
              this.loadInitialPageData();
            });
          } else {
            // if no userPlanId exist, just load
            this.loadInitialPageData();
          }
        }
      }
      else
      {
        //if not new user just login
        this.loadInitialPageData();
      }
    });
  }
  //workaround to handle save plan to user profile without redirect after user is redirected to my-account from PSE after user use activation link
 /* loadData(){
    this.userInfoService.isNewUser().subscribe(isNewUser =>{
      var userPlanId = this.cookieService.get("userPlanId");
      if(isNewUser && userPlanId != null && userPlanId.length > 0) {
        // if userPlanId exist, Try to save plan for this user then load
        this.userPlanService.assignUserPlanToCurrentUser(userPlanId).subscribe(result => {
          this.loadInitialPageData();
        });
      }
      else {
        //if not new user just login
        this.loadInitialPageData();
      }
    });
  }*/
loadInitialPageData(){
  this.userInfoService.getUserInfo().subscribe(user => {
    if (user != null && user.value != null) this.user = JSON.parse(user.value)
    this.cloneTemp()
  })
  this.authService.updateLoggedInEmail.emit(this.authService.getUserEmail())
  this.userPlanService.getUserPlans().subscribe(data => {
    this.userPlans = data
  })

  this.contentService.getContentForPage('Profile', this.currentLang == 'fr', '').subscribe(data => {
    this.pageContent = data
  })
}
  startNewPlan() {
    let path = this.currentLang == 'en' ? 'step-1' : 'etape-1'
    this.userPlanService.createUserPlan().subscribe(userPlanId => {
      this.cookieService.put("userPlanId", userPlanId)
      this.router.navigate([(this.currentLang == 'en' ? '' : 'fr/') + path])
    })
  }

  editProfile() {
    this.authService.gotoEditProfile();
  }

  deleteUserPlan(userPlanId: string) {
    this.userPlanService.deleteUserPlan(userPlanId).subscribe(() => {
      this.ngOnInit()
    })
  }

  cloneUserPlan(userPlanId){
    this.userPlanService.cloneUserPlan(userPlanId).subscribe(newUserPlanId => {
      let path = this.currentLang == 'en' ? 'step-1/clone/true' : 'etape-1/clone/true'
      this.cookieService.put("userPlanId", newUserPlanId)
      this.router.navigate([(this.currentLang == 'en' ? '' : 'fr/') + path])
    })
  }

  cloneTemp(){
    this.tempUserModel = Object.assign(new UserModel(), this.user)
  }

  updateUserInfo(){
    this.user = this.tempUserModel
    this.userInfoService.updateUserInformation(this.user).subscribe(() => {
      this.showEditCommunication = false
    })
  }
}
