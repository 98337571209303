import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {PublicSecureService} from "../../services/public-secure/public-secure.service.interface";
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {GoogleAnalyticsServiceInterface} from "../../services/google-analytics-service/google-analytics.interface";
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() stepNumber: number
  @Output() loginClicked: EventEmitter<any> = new EventEmitter<any>()

  constructor(
    @Inject('GoogleAnalyticsService') private googleAnalyticsService: GoogleAnalyticsServiceInterface,
    public translate: TranslateService,
    public router: Router,
    @Inject('PublicSecureService') private publicSecureService: PublicSecureService,
    @Inject('AuthService') public authService: AuthService,
    private cookieService: CookieService) {
  }

  ngOnInit(): void {}

  login() {
    if (this.stepNumber != null && (this.stepNumber == 1 || this.stepNumber == 2)) this.loginClicked.emit()
    else {
      this.googleAnalyticsService.eventEmitter("login-initiated", "Login/Register", "Navigate to Public Secure", "click", this.router.url);
      var sourceUrl = this.stepNumber == 3 ? (this.router.url + '/true') : this.router.url;

      this.cookieService.put("lastUri",sourceUrl);
      this.authService.login(sourceUrl, this.translate.currentLang)
    }
  }
}
