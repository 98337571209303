<app-sub-header [isDarkTheme]="true" [title]="'Homepage.Title' | translate"></app-sub-header>

<div class="ontario-padding-bottom-48-!">
  <div id="back-button-div" class="ontario-row ontario-margin-bottom-40-! ontario-padding-top-24-!">
    <div class="ontario-columns ontario-small-9">
      <app-back-button></app-back-button>
    </div>
  </div>

  <div class="ontario-row">
    <div class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-3">
      <h1 class="title" [class.smaller-title]="currentLang == 'fr'"
          id="workplace-covid-risk-title">{{'Homepage.WorkplaceCovidRisks&SafetyPlan' | translate}}</h1>

      <div class="text-content text-content-smaller"
           [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'PrimaryTitleSubtext').contentHTML">
      </div>
    </div>

    <div class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-9 border-left">
      <ng-container *ngFor="let content of [contentDisplayUtil.getContentForPage(pageContent, 'PrimaryPageContent')]">
        <h2 id="develop-workplace-safety-plan-title"
            class="ontario-margin-bottom-24-!"
            [innerHTML]="content.title"></h2>
        <div class="ontario-margin-bottom-32-! text-content text-content-larger"
             [innerHTML]="content.contentHTML"></div>
      </ng-container>
      <div class="ontario-margin-bottom-24-!">
        <div class="circle-container">
          <div class="circle-that-img">
            <img alt="{{'General.UnderstandThePotentialRisksInYourWorkplace' | translate}}"
                 src="assets/images/magnifying-triangle-blue.svg">
          </div>
          <img src="assets/images/chevron-blue.svg">
          <div class="circle-that-img">
            <img alt="{{'General.CustomizeThePlanWithApplicableDetails' | translate}}"
                 src="assets/images/treasure-map-blue.svg">
          </div>
          <img src="assets/images/chevron-blue.svg">
          <div class="circle-that-img">
            <img alt="{{'General.ReviewAndDownloadTheRecommendedPlan' | translate}}"
                 src="assets/images/checklist-blue.svg">
          </div>
        </div>
        <div class="text-container">
          <div>
            {{'General.UnderstandThePotentialRisksInYourWorkplace' | translate}}
          </div>
          <div>
            {{'General.CustomizeThePlanWithApplicableDetails' | translate}}
          </div>
          <div>
            {{'General.ReviewAndDownloadTheRecommendedPlan' | translate}}
          </div>
        </div>
      </div>
      <div *ngIf="!authService.isUserLoggedIn()" class="sub-row">
        <div class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-8">
          <div id="sign-up-div" class="no-bottom-margin text-content text-content-smaller"
               [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'SignUpInstructions').contentHTML">
          </div>
        </div>
        <div class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-4 no-padding-div">

          <button id="login-bouton"
            class="ontario-button--primary ontario-button"
            (click)="login()">{{"Homepage.Register2DevelopSafetyPlan" | translate }}</button>
          <button id="develop-guest-safety-plan-button"
                  class="ontario-button--tertiary ontario-button"
                  (click)="goToStep1()">{{"Homepage.DevelopMySafetyPlanAsGuest" | translate }}</button>

        </div>

      </div>
      <div *ngIf="authService.isUserLoggedIn()" class="sub-row">
        <div class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-5">
          <div id="signed-up-div" class="no-bottom-margin text-content"
               [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'SignedInInstructions').contentHTML">
          </div>
        </div>
        <div class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-7 div-align-topish-right">

          <button id="goToMyAccount-button"
                  class="ontario-button--primary ontario-button btn-black ontario-margin-left-0-! ontario-margin-right-0-!"
                  (click)="goToMyAccount()">{{"Homepage.GoToMyAccount" | translate }}</button>
          <div id="or-div">{{"Homepage.Or" | translate }}</div>
          <button id="goToStep1-button"
                  class="ontario-button--primary ontario-button"
                  (click)="goToStep1()">{{"Homepage.DevelopMySafetyPlan" | translate }}</button>
        </div>
      </div>
      <div class="sub-row">
        <div id="prequel-disclaimer"
             class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12 text-content-smallest add-half-space-above"
             [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'DisclaimerText').contentHTML"></div>
        <!--        <div id="disclaimer"-->
        <!--             class="ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12 text-content-smallest add-half-space-above"-->
        <!--             [innerHTML]="'Homepage.DisclaimerThisToolDoesNotReplaceTheOccupationalHealth' | translate"></div>-->
      </div>
    </div>
  </div>
</div>
