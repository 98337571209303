import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {AuthService} from "../../services/auth.service/auth.service.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-steps-banner',
  templateUrl: './steps-banner.component.html',
  styleUrls: ['./steps-banner.component.scss']
})
export class StepsBannerComponent implements OnInit {

  @Input() activeStep: number = 0
  @Output() loginClicked: EventEmitter<any> = new EventEmitter<any>()

  isLoggedIn: boolean = false
  currentLang: string

  constructor(@Inject(LOCALE_ID) public locale: string,
              @Inject('AuthService') private authService: AuthService,
              public route: ActivatedRoute,
              public router: Router,
              public translateService: TranslateService) {
    router.events.subscribe(() => {
      this.isLoggedIn =  this.authService.isUserLoggedIn();
    })

    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    this.isLoggedIn =  this.authService.isUserLoggedIn();

    this.authService.updateLoggedInEmail.subscribe(name => {

      this.isLoggedIn = this.authService.isUserLoggedIn();
    });
  }

  navigateAway(stepNumber) {
    if (stepNumber < this.activeStep) {
      let navigation = (this.currentLang == 'fr' ? '../etape-' : '../step-') + stepNumber
      this.router.navigate([navigation], {relativeTo: this.route})
    }
  }
}
