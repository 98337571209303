<app-sub-header [isDarkTheme]="true" [title]="'General.MyAccount' | translate"></app-sub-header>

<div class="ontario-row main-content">

  <div class="ontario-column ontario-small-12 ontario-large-9 content-body ontario-margin-bottom-24-!">
    <div [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'TitleSubtext').contentHTML"></div>
  </div>

  <div class="ontario-column ontario-small-12 ontario-large-3 float-right">

    <div class="information-panel">
      <div class="grey-box-header" [class.edit-mode]="showEditCommunication">
        {{'Profile.MyCommunications' | translate}}
        <button *ngIf="!showEditCommunication"
                id="edit-communication-button"
                [attr.aria-label]="'Profile.EditProfileAltText' | translate"
                (keyup.enter)="showEditCommunication = true" (click)="showEditCommunication = true">
          <img src="assets/images/edit-crayon-white.svg" [alt]="'Profile.MyCommunications' | translate">
        </button>
      </div>
      <div class="grey-box-body ontario-column ontario-padding-bottom-64-! tombstone-div">
        <div class="ontario-margin-bottom-16-! ontario-padding-left-16-!">
          <table *ngIf="user != null && !showEditCommunication">
            <tr>
              <td class="image-cell">
                <fa-icon
                        *ngIf="user.IsPeriodicEmailForPlanEffectivenesUpdateEnabled"
                        [icon]="faCheck" class="fas fa-check fa-2x"></fa-icon>
                <fa-icon
                        *ngIf="!user.IsPeriodicEmailForPlanEffectivenesUpdateEnabled"
                        [icon]="faTimes" class="fas fa-times fa-2x"></fa-icon>
              </td>
              <td>{{'Profile.EmailOptReminderToReview' | translate}}</td>
            </tr>
            <tr>
              <td class="image-cell">
                <fa-icon
                        *ngIf="user.IsEmailCommunicationAboutLegislationChangesEnabled"
                        [icon]="faCheck" class="fas fa-check fa-2x"></fa-icon>
                <fa-icon
                        *ngIf="!user.IsEmailCommunicationAboutLegislationChangesEnabled"
                        [icon]="faTimes" class="fas fa-times fa-2x"></fa-icon>
              </td>
              <td>{{'Profile.EmailOptImportantTopics' | translate}}</td>
            </tr>
            <tr>
              <td class="image-cell">
                <fa-icon *ngIf="user.IsUserResearchParticipationEnabled" [icon]="faCheck"
                         class="fas fa-check fa-2x"></fa-icon>
                <fa-icon
                        *ngIf="!user.IsUserResearchParticipationEnabled"
                        [icon]="faTimes" class="fas fa-times fa-2x"></fa-icon>
              </td>
              <td id="participation-td">{{'Profile.EmailOptParticipation' | translate}}</td>
            </tr>
          </table>
          <div id="edit-communication-mode" *ngIf="showEditCommunication && tempUserModel">

            <div class="ontario-checkboxes__item">
              <input type="checkbox"
                     [(ngModel)]="tempUserModel.IsPeriodicEmailForPlanEffectivenesUpdateEnabled"
                     id="periodic-reminder"
                     class="ontario-checkboxes__input">
              <label class="ontario-checkboxes__label parent-checkbox" for="periodic-reminder">{{"Profile.EmailOptReminderToReview" | translate}}</label>
            </div>
            <div class="ontario-checkboxes__item">
              <input type="checkbox"
                     [(ngModel)]="tempUserModel.IsEmailCommunicationAboutLegislationChangesEnabled"
                     id="rules-legislation"
                     class="ontario-checkboxes__input">
              <label class="ontario-checkboxes__label parent-checkbox" for="rules-legislation">{{"Profile.EmailOptImportantTopics" | translate}}</label>
            </div>
            <div class="ontario-checkboxes__item">
              <input type="checkbox"
                     [(ngModel)]="tempUserModel.IsUserResearchParticipationEnabled"
                     id="research"
                     class="ontario-checkboxes__input">
              <label class="ontario-checkboxes__label parent-checkbox" for="research">{{"Profile.EmailOptParticipation" | translate}}</label>
            </div>

            <div class="buttons-container">
              <button id="cancel" (click)="cloneTemp(); showEditCommunication = false" (keyup.enter)="cloneTemp(); showEditCommunication = false" class="link-like-span">{{'Profile.Cancel' | translate}}</button>
              <button id="update" class="ontario-button--primary" (click)="updateUserInfo()" (keyup.enter)="updateUserInfo()">{{'Profile.Save' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="information-panel">
      <div class="grey-box-header">
        <span [innerHTML]="'Profile.MyProfile' | translate"></span>
        <button id="edit-profile-button" [attr.aria-label]="'Profile.EditProfileAltText' | translate"
                (keyup.enter)="editProfile()" (click)="editProfile()">
          <img src="assets/images/edit-crayon-white.svg" [alt]="'Profile.EditProfileAltText' | translate">
        </button>
      </div>
      <div class="grey-box-body ontario-column ontario-padding-bottom-64-! tombstone-div">
        <div class="ontario-padding-left-16-!">
          <div class="grave-name">
            {{'Tombstone.ContactEmailAddress' | translate}}
          </div>
          <div class="epitaph ontario-padding-bottom-24-!">
            {{contentDisplayUtil.replaceEmptyStringWithNa(authService.getUserEmail())}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ontario-column ontario-small-12 ontario-large-3 float-right">
    <button class="ontario-button ontario-button--primary" id="start-new-button" (click)="startNewPlan()"
            (keyup.enter)="startNewPlan()">
      {{'Profile.StartNewPlan' | translate}}
    </button>
  </div>

  <div class="ontario-column ontario-small-12 ontario-large-9 content-body">

    <h3 id="safety-plan-title" class="border-bottom ontario-padding-bottom-4-! ontario-margin-bottom-32-!">
      {{'Profile.MySafetyPlans' | translate}}
    </h3>

    <app-plan-tombstone #tombstone *ngFor="let userPlan of userPlans" [userPlan]="userPlan" [includeDates]="true"
                        [includeStatusAndPostalCode]="true"
                        (deleteUserPlanEmitter)="deleteUserPlan($event)"
                        (cloneUserPlanEmitter)="cloneUserPlan($event)"></app-plan-tombstone>
  </div>

  <app-feedback-panel [pageName]="'My Account'"></app-feedback-panel>
</div>
