<app-steps-banner [activeStep]="3"></app-steps-banner>

<div id="step-3-body" class="ontario-row ontario-margin-top-40-!">

    <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9">

        <h2 class="step-title ontario-margin-bottom-8-!"
            [innerHTML]="contentDisplayUtil.getContentForPage(pageContent, 'TitleSubtext').title"></h2>



        <app-success-banner *ngIf="newlyRegistered"
                            [successTitle]="('Profile.YourPlanHasBeenSavedToYourAccount' | translate)"
                            [buttonText]="('Profile.ViewMyAccount' | translate)"></app-success-banner>
    </div>


    <div class="ontario-column ontario-small-12 ontario-medium-12 ontario-large-9 ontario-padding-bottom-40-!">
        <h3 class="main-section-title section-titleontario-padding-bottom-4-! ontario-margin-bottom-24-!">
            {{"Step3.RecommendedPrecautionsThatYouWouldImplementAtYourWorkplace" | translate}}
        </h3>

        <button id="start-new-button" class="ontario-button ontario-button--secondary step-three-button" (click)="startNewPlan()" (keyup.enter)="startNewPlan()">
            {{"Profile.StartNewPlan" | translate}}
        </button>

        <button id="clone-button" class="ontario-button ontario-button--secondary step-three-button" *ngIf="isLoggedIn" (click)="duplicateThisPlan()" (keyup.enter)="duplicateThisPlan()">
            {{"Profile.Clone" | translate}}
        </button>

        <hr class="line-break">

        <app-plan-tombstone #tombstone [userPlan]="userPlan" [includeDates]="true" [showDownload]="true"
                            (downloadUserPlanEmitter)="downloadUserPlanPdf()"
                            (copyShareableLink)="monitorSharing()"></app-plan-tombstone>
    </div>

    <div class="information-panel ontario-column ontario-small-12 ontario-large-3 ontario-margin-bottom-40-! float-right">
        <ng-container *ngFor="let content of [contentDisplayUtil.getContentForPage(pageContent, 'SidebarContent')]">
            <div class="grey-box-header ontario-column">
                <h1 id="how-to-use" [innerHTML]="content.title"></h1>
            </div>
            <div class="grey-box-body ontario-column ontario-padding-bottom-64-!" [innerHTML]="content.contentHTML">
            </div>

        </ng-container>
    </div>

    <div class="ontario-column ontario-small-12 ontario-large-8 ontario-padding-bottom-40-!"
         *ngFor="let userPlanSection of userPlanSections; let i = index">

        <h4 class="border-bottom section-heading">
            <div class="heading-text">
                <div class="section-counter">
                    <div class="number">{{i + 1 | number: '2.0-0'}}</div>
                </div>
                <div class="heading">{{currentLang == 'en' ? userPlanSection.section.sectionName : userPlanSection.section.sectionNameFr}}</div>
            </div>
            <button class="edit-icon noprint"
                    *ngIf="userPlanSection.section.id != 'Important Notes'"
                    (click)="navigateToEdit(userPlanSection.section.sectionName)"
                    (keyup.enter)="navigateToEdit(userPlanSection.section.sectionName)">
                <img
                        [attr.aria-label]="('Step3.Edit' | translate) + (currentLang == 'en' ? userPlanSection.section.sectionName : userPlanSection.section.sectionNameFr)"
                        src="assets/images/edit-crayon.svg">
            </button>
        </h4>

        <ng-container *ngFor="let question of userPlanSection.section.questions">
            <div class="ontario-column ontario-small-12 paragraph-div"
                 *ngIf="question.hasAnyAnswer() || question.inputType.inputTypeName == 'Paragraph Heading' || question.inputType.inputTypeName == 'Paragraph Body' || question.inputType.inputTypeName == 'Bullet List'">

                <img src="assets/images/double-chevron.svg" aria-hidden="true" class="chevron">
                <p *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With Text') > -1"
                   [innerHTML]="(question.inputType.inputTypeName.toLowerCase().indexOf('input') > -1 ? (currentLang == 'en' ? question.displayText : question.displayTextFr) + ' ' + question.answers[0].answerText : question.answers[0].answerText)"></p>
                <p *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With Numeric') > -1"
                   [innerHTML]="(question.inputType.inputTypeName.toLowerCase().indexOf('input') > -1 ? (currentLang == 'en' ? question.displayText : question.displayTextFr) + ' ' + question.answers[0].answerNumeric : question.answers[0].answerNumeric)"></p>

                <p class="generic-p"
                        *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With Text') == -1 && question.inputType.inputTypeName.indexOf('Checkbox With Numeric') == -1"
                        [innerHTML]="currentLang == 'en' ? question.displayText : question.displayTextFr"></p>


                <ng-container *ngIf="question.inputType.inputTypeName == 'Paragraph Heading'">1
                    <p class="paragraph-item" *ngFor="let paragraph of question.options"
                       [innerHTML]=" currentLang == 'en' ? paragraph.displayText : paragraph.displayTextFr"></p>
                </ng-container>


            </div>

            <div class="ontario-row ontario-margin-left-16-!" *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With List') > -1 || question.inputType.inputTypeName.indexOf('Bullet List') > -1">
                <ul>
                    <li *ngFor="let item of question.options"
                        [innerHTML]="currentLang == 'en' ? item.displayText : item.displayTextFr"></li>
                </ul>
            </div>

            <ng-container
                    *ngIf="question.inputType.inputTypeName.indexOf('Checkbox With List') == -1 && (question.answers != null) && question.options != null && question.options.length > 0">
                <ng-container *ngFor="let answers of [question.sortAnswers()]">
                    <ng-container *ngFor="let answer of answers; let i = index">
                        <div class="ontario-row option-row ontario-margin-left-4-!" *ngIf="i%2 == 0">
                            <ng-container
                                    *ngFor="let option of (i+1 <= question.answers.length-1 ? [question.getOptionById(answers[i].optionId), question.getOptionById(answers[i+1].optionId)] : [question.getOptionById(answers[i].optionId)])">
                                <div *ngIf="option != null && option.inputType != null && option.inputType.inputTypeName != null" class="ontario-column ontario-small-12 ontario-large-6 selected-option-div ontario-margin-bottom-16-!">
                                    <ng-container *ngFor="let inputType of [option.inputType.inputTypeName.toLowerCase()]">
                                        <div class="checkmarked-row">
                                            <img src="assets/images/checkmark-black.svg"
                                                 [attr.aria-label]="'Step3.SelectedOptionImage' | translate">
                                            <p class="option-text"
                                               *ngIf="inputType.indexOf('checkbox with text') > -1"
                                               [innerHTML]="(inputType.indexOf('input') > -1 ? (currentLang == 'en' ? option.displayText : option.displayTextFr) + ' ' + question.getAnswerByOptionId(option.id).answerText : question.getAnswerByOptionId(option.id).answerText)"></p>
                                            <p class="option-text"
                                               *ngIf="inputType.indexOf('checkbox with text') == -1"
                                               [innerHTML]="currentLang == 'en' ? option.displayText : option.displayTextFr"></p>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div class="buttons-column ontario-column ontario-small-12 ontario-medium-12 ontario-large-9 ontario-padding-bottom-40-! noprint">
        <app-buttons-container #buttons
                               [backRoute]="currentLang == 'en' ? '/step-2' : '/etape-2'"
                               [userPlan]="userPlan"
                               [blueBoxContent]="contentDisplayUtil.getContentForPage(pageContent, 'BlueBox')"
                               [userPlanSections]="userPlanSections"
                               [stepNumber]="3"></app-buttons-container>
    </div>

    <app-feedback-panel [userPlan]="userPlan" [pageName]="'Step 3'"></app-feedback-panel>
</div>
