<ng-container *ngIf="showHeaderFooter">
  <div *ngIf="errorMsg.length > 0">{{errorMsg}}</div>

  <app-header></app-header>
  <main id="main-content" class="container">
    <router-outlet *ngIf="hasCheckedForUserEmail"></router-outlet>
  </main>
  <session-timeout></session-timeout>
  <div class="loading-indicator" id="progress-container" *ngIf="spinnerService.visibility == true">
    <mat-progress-spinner id="progress-spinner" defaultColor="#FFFFFF" mode="indeterminate"></mat-progress-spinner>
  </div>
  <app-footer></app-footer>
</ng-container>

<ng-container *ngIf="!showHeaderFooter">
  <router-outlet *ngIf="hasCheckedForUserEmail"></router-outlet>
</ng-container>
