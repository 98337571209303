import {Inject, Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {AuthService} from "../services/auth.service/auth.service.interface";
import {environment} from "../../environments/environment";
import {tap} from "rxjs/operators";
import {LoggingService} from "../services/app-insight-logging/logging.service.interface";
import {SpinnerService} from "../services/spinner-service/spinner.service";

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

  public count: number = 0

  constructor(@Inject('AuthService') private authService: AuthService,
              @Inject('LoggingService') private loggingService: LoggingService,
              @Inject('SpinnerService') private spinnerService: SpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.count++
    if (this.count == 1) {
      this.spinnerService.show()
    }
    //set Authentication Header only in /api/ call.
    if (request.url.toString().startsWith(environment.apiBaseUrl) && this.authService.isUserLoggedIn() && (this.authService.isSessionExpiryValid() || this.authService.isSessionExpiryNull())) {
      return next.handle(request).pipe(
        tap((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            let expire = evt.headers.get('Session-Expiry');
            if (expire != null && expire != undefined) {
              this.authService.setSessionExpiry(Number(expire))
            }
            this.reduceCounter()
          }
        })
      )
    } //user is logged in but their session expired
    else if (this.authService.isUserLoggedIn() && !this.authService.isSessionExpiryValid()){
      this.authService.gotoLogout()
      return of(null)
    }
    else { //user is not logged in but still can perform actions
      return next.handle(request).pipe(
        tap((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.reduceCounter()
          }
        })
      )
    }
  }

  public reduceCounter() {
    this.count--;
    if (this.count === 0) {
      this.spinnerService.hide();
    }
  }
}
