import {Inject, Injectable} from '@angular/core';
import {ApplicationInsights, DistributedTracingModes} from "@microsoft/applicationinsights-web";
import {environment} from "../../../environments/environment";
import {LoggingService} from "./logging.service.interface";
import {AuthService} from "../auth.service/auth.service.interface";

@Injectable()
export class AppInsightLoggingService implements LoggingService {

  appInsights: ApplicationInsights;
  constructor( @Inject('AuthService') private authService: AuthService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
      }
    });

    this.init()
  }

  init(){
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.appInsights.setAuthenticatedUserContext(this.authService.getUserEmail());
    this.authService.updateLoggedInEmail.subscribe(name => {
      this.appInsights.setAuthenticatedUserContext(name)
    });
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({name: name, average: average}, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({exception: exception, severityLevel: severityLevel});
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({message: message}, properties);
  }
}
