import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {UserPlanService} from "./user-plan.service.interface";
import {Observable} from "rxjs";
import {UserPlanModel} from "shared-library";

declare function openNewTab(url)

@Injectable({
  providedIn: 'root'
})
export class RestfulUserPlanService implements UserPlanService {

  constructor(private httpClient: HttpClient) {
  }

  createUserPlan(): Observable<string> {
    return this.httpClient.post<string>(environment.apiBaseUrl + `userPlan`, {})
  }

  getUserPlanById(planId: any): Observable<UserPlanModel> {
    return this.httpClient.get<UserPlanModel>(environment.apiBaseUrl + `userPlan/${planId}`)
  }
  assignUserPlanToCurrentUser(planId: any): Observable<boolean> {
    return this.httpClient.patch<boolean>(environment.apiBaseUrl + `userPlan/${planId}`,{})
  }
  saveUserPlan(userPlan: UserPlanModel): Observable<void> {
    userPlan.healthUnitUserPlans = userPlan.healthUnitUserPlans?.filter(x => x.id != '' && x.publicHealthUnitId != '' && x.userPlanId != '')
    return this.httpClient.post<void>(environment.apiBaseUrl + `userPlan/save`, userPlan)
  }

  getBusinessRegionsAndPublicHealthUnits(): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiBaseUrl + `userPlan/businessRegions`)
  }

  getUserPlans(): Observable<UserPlanModel[]> {
    return this.httpClient.get<UserPlanModel[]>(environment.apiBaseUrl + 'userPlan/userPlans')
  }

  deleteUserPlan(userPlanId): Observable<UserPlanModel[]> {
    return this.httpClient.delete<any>(environment.apiBaseUrl + `userPlan/${userPlanId}`)
  }

  cloneUserPlan(userPlanId): Observable<string> {
    // @ts-ignore
    return this.httpClient.get<string>(environment.apiBaseUrl + `userPlan/clone/${userPlanId}`, {responseType: 'text' as const})
  }
}
