import {ChangeDetectorRef, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UserPlanService} from "../../services/user-plan-service/user-plan.service.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {ButtonsContainerComponent} from "../../core-components/buttons-container/buttons-container.component";
import {QuestionAnswerService} from "../../services/question-answer-service/question-answer.interface";
import {zip} from "rxjs";
import {ContentService} from "../../services/content-service/content.sevice.interface";
import {
  ContentDisplayUtil,
  ContentModel, InputTypeLoopComponent, mappers,
  PlanTombstoneComponent,
  ResetConfirmationComponent, SectionCompletionStatusModel,
  UserPlanModel
} from "shared-library";
import {StatusEnum} from "../../enums/status.enum";
import mapToUserPlanSectionModels = mappers.mapToUserPlanSectionModels;
import mapToAnswerModel = mappers.mapToAnswerModel;
import {CookieService} from "ngx-cookie";

@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  currentLang: string
  userPlanId: string = ""

  openArray: Array<boolean> = new Array()
  userPlan = new UserPlanModel('')

  showResetConfirmation: boolean = false
  userPlanSections: any[] = []

  urlSectionName: string = ''
  validationTriggered = false

  showRequirementError = false
  pageContent: ContentModel[] = []
  contentDisplayUtil = ContentDisplayUtil

  newlyRegistered: boolean = false

  ignoreFirstNav = true

  @ViewChild("buttons") public buttons: ButtonsContainerComponent | undefined;
  @ViewChild("tombstone") public tombstone: PlanTombstoneComponent | undefined;
  @ViewChild("resetConfirmation") public resetConfirmation: ResetConfirmationComponent | undefined;
  @ViewChildren("inputs") public inputs: QueryList<InputTypeLoopComponent>;

  constructor(public translateService: TranslateService,
              @Inject('UserPlanService') public userPlanService: UserPlanService,
              @Inject('QuestionAnswerService') public questionAnswerService: QuestionAnswerService,
              @Inject('ContentService') public contentService: ContentService,
              public route: ActivatedRoute,
              public router: Router,
              public cdr: ChangeDetectorRef,
              private cookieService: CookieService) {
    this.currentLang = translateService.currentLang
    translateService.onLangChange.subscribe(lang => {
      this.currentLang = lang.lang
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.userPlanId = this.cookieService.get("userPlanId");

    this.contentService.getContentForPage('Step2', this.currentLang == 'fr', this.userPlanId).subscribe(content => {
      this.pageContent = content
    })

    this.urlSectionName = this.route.snapshot.params["sectionName"];
    this.newlyRegistered = this.route.snapshot.params["newlyRegistered"];

    if (this.userPlanId) {
      zip(this.userPlanService.getUserPlanById(this.userPlanId), this.questionAnswerService.getSectionsAndQuestionsByStepAndPlanId(2, this.userPlanId, false)).subscribe(data => {
        this.userPlan = data[0]
        this.userPlanSections = mapToUserPlanSectionModels(data[1])

        if (this.userPlanSections != null && this.userPlanSections.length > 0) {
          //track open section
          this.openArray = new Array(this.userPlanSections.length)

          this.userPlanSections.forEach((x, i) => {
            //If we have a section specified in the url params, ensure this is the section that defaults open
            let curMatch = this.urlSectionName != '' && this.urlSectionName != undefined && ContentDisplayUtil.parseStringForUrl(x.section.sectionName) == this.urlSectionName.toLowerCase()
            if (curMatch) this.openArray[i] = true
          })
          if (this.urlSectionName == null || this.urlSectionName == undefined || this.urlSectionName.length == 0) this.openFirstIncompleteSection();

        }

        //For navigation from step 3 to step 2 (wherein a specific section must be opened and scrolled to)
        if (this.urlSectionName != null && this.urlSectionName != '') {
          this.cdr.detectChanges()
          let active = document.getElementsByClassName('active')
          if (active != null && active.length > 0 && this.openArray[0] != true) {
            active[0].scrollIntoView();
            let bannerHeight = document.getElementsByClassName('banner-div')[0].scrollHeight + 45
            window.scrollBy(0, -1 * bannerHeight)
          }
        }
      })
    } else this.router.navigate([this.currentLang == 'fr' ? '/fr' : '/'])
  }

  openFirstIncompleteSection(index = 0) {
    let foundFirst = false
    this.openArray.fill(false, 0, this.openArray.length)
    while (!foundFirst && index < this.userPlanSections.length) {
      let upSection = this.userPlanSections[index];
      if (upSection.sectionCompletionStatuses == null || upSection.sectionCompletionStatuses.length == 0 || upSection.sectionCompletionStatuses.filter(x => x.sectionStatusId == StatusEnum.COMPLETED).length == 0) {
        this.toggle(index)
        foundFirst = true
      }
      index++;
    }
  }

  checkCompletionStatus(sectionId: string, i: number) {
    if (this.userPlanSections == null || i == null) return false
    let userSection = this.userPlanSections.filter(x => x.sectionId == sectionId)
    if (userSection != null && userSection.length > 0) {
      let statuses = userSection[0].sectionCompletionStatuses
      if (statuses != null && statuses[0] != null && statuses[0].sectionStatusId == StatusEnum.COMPLETED) {
        return true
      } else
        return false
    }
    return false
  }

  togglePanel(i: number) {
    let indexOfOpened = this.openArray.indexOf(true)
    if (indexOfOpened > -1) {
      let userPlanSectionToSave = this.userPlanSections[indexOfOpened]
      let answers = this.getAnswersFromQuestions(userPlanSectionToSave.section.questions)

      this.questionAnswerService.saveQuestionAnswers(this.userPlan.id, answers, userPlanSectionToSave.section.id).subscribe(() => {
        this.toggle(i)
      })
    } else this.toggle(i)
  }

  toggle(index) {
    this.showRequirementError = false

    let value = this.openArray[index]
    this.openArray.fill(false)
    this.openArray[index] = !value

    this.cdr.detectChanges()

    let active = document.getElementsByClassName("active")
    if (active != null && active.length > 0 && !this.ignoreFirstNav) {
      active[0].scrollIntoView();
      let bannerHeight = document.getElementsByClassName('banner-div')[0].scrollHeight + 45
      window.scrollBy(0, -1 * bannerHeight)
    }
    this.ignoreFirstNav = false
  }

  saveSectionBeforeLogin() {
    this.togglePanel(0)
    this.buttons.login()
  }

  toggleFirst() {
    if (this.openArray[0] != true) this.togglePanel(0)
    this.inputs.forEach(x => x.reset())
  }

  showReset() {
    this.showResetConfirmation = true;
    this.cdr.detectChanges()

    // @ts-ignore
    document.getElementById("warning-icon-background").scrollIntoView();
    let bannerHeight = document.getElementsByClassName('banner-div')[0].scrollHeight + 45
    window.scrollBy(0, -1 * bannerHeight)
  }

  getAnswersFromQuestions(questions): any[] {
    let answers = []
    questions.forEach(y => {
      if (y.answers != null && y.answers.length > 0) {
        y.answers.forEach(z => {
          if (z.id != null && z.id.length > 0) answers.push(mapToAnswerModel(z))
        })
      }
    })
    return answers
  }

  completeNext(questions: any, i: number) {
    if (questions.length == 0) return

    let curSectionId = questions[0].sectionId
    let section = this.userPlanSections.filter(x => x.sectionId == curSectionId)[0]
    if (!section.section.meetsSaveRequirements()) {
      this.showRequirementError = true
      return
    }

    let combinedAnswers = this.getAnswersFromQuestions(questions)
    this.questionAnswerService.saveQuestionAnswers(this.userPlan.id, combinedAnswers, curSectionId).subscribe(() => {
      this.questionAnswerService.updateSectionStatus(this.userPlan.id, curSectionId, StatusEnum.COMPLETED).subscribe(() => {
        if (section.sectionCompletionStatuses == null) section.sectionCompletionStatuses = []

        section.sectionCompletionStatuses.push(new SectionCompletionStatusModel('', section.id, StatusEnum.COMPLETED))

        this.openFirstIncompleteSection(i + 1)
      })
    })
  }

  loginClicked(){
    this.buttons.nextClicked(true)
  }
}
